import React, {useContext, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Slider, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageTabs from "../../components/Tabs/LanguageTabs";
import HeaderSwitchers from "../Header/HeaderSwitchers";
import {PageContext} from "../../pages/Page";

import {ReactComponent as SettingsIcon} from '../../assets/icons/settings-icon.svg';
import HeaderAccordion from "../Header/HeaderAccordion";
import {setBalanceButtons} from "../../data/headerData";

function MobileSettings(props) {
    const {
        clientCheck,
        role,
        dictionary,
        show,
        active,
        setActive,
        userStatusCounter,
        setUserStatusCounter,
    } = useContext(PageContext);

    const WARNING_LIMIT = 90;
    const ZERO_BALANCE = 100;

    const [value, setValue] = useState(5);


    return (
        <Accordion
            sx={{'&::before': {display: 'none'}}}
        >
            <AccordionSummary
                sx={{
                    minHeight: '70px',
                    fontSize: 'var(--fz-400)',
                    padding: '13px 16px',
                    lineHeight: 1.5,
                    borderRadius: 0,
                    display: 'flex',
                    width: '100%',
                    fontWeight: '600 !important',
                    textAlign: 'left',
                    border: 'none',
                    backgroundColor: 'white !important',
                    color: 'var(--basic-color)',
                    transition: 'background-color 50ms',
                    borderBottom: '2px solid var(--basic-color-100)',
                    '&.Mui-expanded': {
                        minHeight: '70px',
                        borderBottom: 'none',
                    },
                }}
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <SettingsIcon style={{marginRight: '16px'}}/>
                {dictionary.settings}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '20px',
                    paddingX: '16px !important',
                    gap: '12px'
                }}
            >
                <Slider
                    sx={{
                        pointerEvents: 'none',
                        width: '100%',
                        padding: '0px !important',
                        marginX: '0 !important',
                        marginTop: '20px !important',

                        '& .MuiSlider-track': {
                            backgroundColor: value >= WARNING_LIMIT && '#FD4D5C',
                            borderBottomRightRadius: +value === ZERO_BALANCE ? 10 : 0,
                            borderTopRightRadius: +value === ZERO_BALANCE ? 10 : 1.0,
                        },
                        '& .MuiSlider-valueLabel': {
                            backgroundColor: value >= WARNING_LIMIT && '#FD4D5C',
                        },
                    }}
                    // className={sliderClass}
                    aria-label="Always visible"
                    value={2}
                    step={5}
                    // onChange={handleChange}
                    // valueLabelFormat={valueLabelFormat}
                    valueLabelDisplay="on"
                />
                {role === 'adm' && (
                    <HeaderAccordion
                        clientCheck={clientCheck}
                        title={'99 000 UAH'}
                        currentClass={
                            'header__accordion-button header__accordion-button--balance'
                        }
                        buttons={setBalanceButtons}
                    />
                )}
                <HeaderSwitchers style={{marginLeft: '0'}}
                                 userStatusCounter={userStatusCounter}
                                 setUserStatusCounter={setUserStatusCounter}
                                 switcherSx={{
                                     marginLeft: '0px !important',
                                     flexDirection: 'row-reverse',
                                     backgroundColor: 'white !important'
                                 }}
                />
                <LanguageTabs
                    onlyTabs={true}
                    tabsClass={'tabs-language--sidebar--settings'}
                    tabsSx={{
                        button: {
                            flexGrow: 1 + ' !important',
                        }
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );
}

export default MobileSettings;