import {useState, useContext, useEffect} from 'react';

import {Popper, Fade, ClickAwayListener, Box} from '@mui/material';

import { FlexBox } from '../../../mui/theme';
import { ReactComponent as RenewIcon } from '../../../assets/icons/renew.svg';
import { ReactComponent as SuspendIcon } from '../../../assets/icons/suspend.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as PassDialogueIcon } from '../../../assets/icons/pass.svg';
import { ReactComponent as AddTagIcon } from '../../../assets/icons/add-tag.svg';
import { ReactComponent as BlockIcon } from '../../../assets/icons/block.svg';
import { ReactComponent as UnblockIcon } from '../../../assets/icons/unblock.svg';

import MyTooltip from '../Tooltip/MyTooltip';
import MyButton from '../Button/MyButton';
import Title from '../Title/Title';

import { PageContext } from '../../../pages/Page';

const MyPopper = ({
    tooltipTitle,
    buttonTitle,
    submitButtonClass = '',
    status,
    popperClass = '',
    submitButtonHandler,
    currentId,
    children,
    cleareFields,
    cleareFieldsHandler,
}) => {
    const { dictionary, screenSize } = useContext(PageContext);

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const renderCurrentIcon = (status) => {
        if (status === 'stopped') {
            return <RenewIcon aria-describedby={id} onClick={handleClick} />;
        } else if (status === 'active' || status === 'blocked') {
            return <SuspendIcon aria-describedby={id} onClick={handleClick} />;
        } else if (status === 'deleting') {
            return <DeleteIcon aria-describedby={id} onClick={handleClick} />;
        } else if (status === 'pass_dialogue') {
            return <PassDialogueIcon aria-describedby={id} onClick={handleClick} />;
        } else if (status === 'add_tag') {
            return <AddTagIcon aria-describedby={id} onClick={handleClick} />;
        } else if (status === 'block_user') {
            return <BlockIcon aria-describedby={id} onClick={handleClick} />;
        } else if (status === 'unblock_user') {
            return <UnblockIcon aria-describedby={id} onClick={handleClick} />;
        } else {
            return <></>;
        }
    };


    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        return () => {
            document.body.style.overflow = "";
        };
    }, [open]);

    return (
        <>
            {screenSize === 'xs' && open &&
                <Box
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9,
                    }}
                />
            }
            <MyTooltip position={'top'} title={tooltipTitle}>
                {renderCurrentIcon(status)}
            </MyTooltip>
            <Popper
                className={`popper ${popperClass}`}
                // sx={{backgroundColor: 'red !important'}}
                id={id}
                open={open}
                anchorEl={anchorEl}
                transition
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                            boundary: anchorEl,
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener
                        onClickAway={(e) => {
                            // some poppers have modal windows (dropdown in selects)
                            // in such moments the popper should not be closed
                            // that's why we're doing an inspection
                            // !e.target.classList.contains('body')
                            if (open && !e.target.classList.contains('body')) {
                                setOpen(false);

                                if (cleareFields) {
                                    cleareFieldsHandler();
                                }
                            }
                        }}
                    >
                        <Fade {...TransitionProps} timeout={350}>
                            <FlexBox className="col-20">
                                {tooltipTitle && (
                                    <Title
                                        heading={'h3'}
                                        title={tooltipTitle}
                                        sx={{ textAlign: 'center' }}
                                    />
                                )}
                                {children}
                                <FlexBox className="row-20">
                                    <MyButton
                                        buttonClasses={'btn--cancel'}
                                        title={dictionary?.cancel}
                                        buttonHandler={() => {
                                            setOpen(false);

                                            if (cleareFields) {
                                                cleareFieldsHandler();
                                            }
                                        }}
                                    />
                                    <MyButton
                                        buttonClasses={submitButtonClass}
                                        title={buttonTitle}
                                        buttonHandler={() => {
                                            submitButtonHandler(currentId);
                                            setOpen(false);
                                        }}
                                    />
                                </FlexBox>
                            </FlexBox>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default MyPopper;
