import {
  useState,
  useLayoutEffect,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { Controller, set, useFormContext } from 'react-hook-form';

import { TextField, Box } from '@mui/material';

import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { Calendar } from 'react-multi-date-picker';
import DateObject from 'react-date-object';

import { PageContext } from '../../pages/Page';

import 'react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css';
import './datePicker.scss';

import { locales } from '../../helpers/functions';
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const MyDatePicker = ({
  fieldName,
  clearFilter,
  placeholder,
  withoutTime,
  receivedValue,
}) => {

  const { language, dictionary, screenSize } = useContext(PageContext);
  const { control, setValue, getValues } = useFormContext();

  const [calendarValue, setCalendarValue] = useState('');
  const [dateFormat, setDateFormat] = useState('');
  const [isOpen, setIsOpen] = useState(false);


  const calendarRef = useRef(null);
  const textFieldRef = useRef(null);



  const calendarStyles = {
    zIndex: 1000,
    position: 'absolute',
    left: textFieldRef.current?.offsetLeft,
    top: textFieldRef.current?.offsetTop + 39,
    width: '100%',
    borderRadius: '4px',
    '.rmdp-time-picker div input': {
      width: '35px',
    },
    '.rmdp-wrapper.rmdp-shadow': {
      boxShadow: 'none',
      border: '1px solid secondaryColor.main',
      width: 'auto',
    },
    '.rmdp-day.rmdp-range': {
      backgroundColor: 'secondaryColor.main',
    },
    '.rmdp-day.rmdp-today': {
      backgroundColor: 'secondaryColor.light',
    },
    '.rmdp-day.rmdp-today span': {
      borderRadius: '0',
      backgroundColor: 'secondaryColor.light',
    },
    '.rmdp-week-day': {
      color: 'secondaryColor.main',
      boxShadow: 'none',
    },
    '.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover': {
      backgroundColor: 'secondaryColor.light',
      borderRadius: '0',
    },
    '.rmdp-range-hover.start:not(.force), .rmdp-range.start:not(.force), .rmdp-range-hover.end:not(.force), .rmdp-range.end:not(.force)':
      {
        borderRadius: '0',
      },
    '.rmdp-day span': {
      inset: '0',
    },
    '.rmdp-input': {
      cursor: 'pointer',
    },
    '.rmdp-arrow': {
      borderColor: 'secondaryColor.main',
    },
    '.rmdp-arrow-container:hover': {
      cursor: 'pointer',
      backgroundColor: 'secondaryColor.light',
    },
    /*'.rmdp-panel-body li:hover': {
      backgroundColor: 'red'
    },
    '.rmdp-panel-body li:hover .b-deselect': {
      backgroundColor: 'red'
    },*/
    '.rmdp-focused': {
      backgroundColor: '#196ca7 !important'
    },
    '.rmdp-focused .b-deselect': {
      backgroundColor: '#196ca7 !important'
    },
    '.rmdp-panel-body li ': {
      backgroundColor: '#4789b9'
    },
    '.rmdp-panel-body li .b-deselect': {
      backgroundColor: '#4789b9'
    },
    '.rmdp-time-picker': {
      minWidth: '0px !important',
    },
    '.rmdp-panel': {
      display: 'grid !important',
      width: '125px',
    },
    '.rmdp-panel-body': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginTop: '10px',
    },
    'div:has(.rmdp-time-picker)': {
      display: 'flex',
      position: 'absolute',
      bottom: '0',
      right: '4%',
    },
    '.rmdp-panel-header': {
      height: 0
    },
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const NUM_OF_DATE_CHARACTERS = 10;

  useEffect(() => {
    const replaceSelectWithDiv = () => {
      setTimeout(function () {
        const timePickerElement = document.querySelector(
          '.rmdp-calendar + div'
        );
        if (timePickerElement) {
          timePickerElement.id = 'time-picker';
          const selectElement = timePickerElement.querySelector('.rmdp-input');

          if (selectElement) {
            var options = Array.from(selectElement.options);
            const customDiv = document.createElement('div');
            customDiv.className = 'custom-time-select';

            const selectedTime = document.createElement('div');
            selectedTime.className = 'selected-time';
            customDiv.appendChild(selectedTime);
            var index = 0;
            if (selectElement.selectedIndex != undefined) {
              index = selectElement.selectedIndex;
            }
            selectedTime.textContent =
              selectElement.querySelectorAll('option')[index]?.textContent;
            selectedTime.setAttribute(
              'value',
              selectElement.querySelectorAll('option')[index]?.value
            );

            const label = document.createElement('label');
            label.className = 'custom-time-label';
            label.textContent = dictionary?.choose_time;
            customDiv.appendChild(label);

            const dropdown = document.createElement('div');
            dropdown.className = 'custom-time-options';
            const arrow = document.createElement('span');
            arrow.innerHTML =
              '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-16k9sjp-MuiSvgIcon-root-MuiSelect-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="#196ca7"></path></svg>';
            customDiv.appendChild(arrow);
            arrow.className = 'custom-select-arrow';

            options.forEach((option, i) => {
              const optionDiv = document.createElement('div');
              optionDiv.textContent = option.textContent;
              optionDiv.setAttribute('data-id', i);
              optionDiv.setAttribute('value', option.value);


              optionDiv.onclick = () => {
                options.forEach((option) => {
                  option.classList.remove('selected');
                });
                // customDiv.textContent = option.textContent;
                selectedTime.innerText = option.textContent;
                selectElement.value = option.value;
                selectElement.classList.add('selected');
                selectElement.selectedIndex = i;

                selectedTime.textContent = option.textContent;

                selectElement.dispatchEvent(
                  new Event('change', { bubbles: true })
                );
              };

              dropdown.appendChild(optionDiv);
            });

            document.addEventListener('click', (e) => {
              if (!e.target.closest('.custom-time-select')) {
                customDiv.classList.remove('open');
                dropdown.style.display = 'none';
              } else {
                dropdown.style.display = 'block';
                customDiv.classList.add('open');
              }
              if (e.target.closest('.custom-time-options')) {
                customDiv.classList.remove('open');
                dropdown.style.display = 'none';
              }
            });

            timePickerElement.children[1].insertAdjacentElement(
              'beforebegin',
              customDiv
            );
            timePickerElement.children[1].insertAdjacentElement(
              'beforeend',
              dropdown
            );

            selectElement.style.display = 'none';
            selectElement.parentNode.style.display = 'none';
          }
        }
      }, 100);
    };
    const timer = setTimeout(() => {
      replaceSelectWithDiv();
    }, 100);

    return () => {
      clearTimeout(timer);
      // delete custom time picker
      const timePickerElement = document.querySelector('.rmdp-calendar + div');
      if (timePickerElement) {
        const customDiv = timePickerElement.querySelector(
          '.custom-time-select'
        );
        if (customDiv) {
          customDiv.remove();
        }
        const dropdown = timePickerElement.querySelector(
          '.custom-time-options'
        );
        if (dropdown) {
          dropdown.remove();
        }
      }
    };
  }, [calendarValue, getValues(fieldName), isOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const selectedTime = document.querySelector('.selected-time');
      if (!selectedTime) return;

      const inputHours = document.getElementsByName('hour')[0];
      const inputMinutes = document.getElementsByName('minute')[0];
      const buttonsUp = document.querySelectorAll('.rmdp-up');
      const buttonsDown = document.querySelectorAll('.rmdp-down');
      const options = document.querySelectorAll('.custom-time-options div');
      if (
        !inputHours ||
        !inputMinutes ||
        !buttonsUp ||
        !buttonsDown ||
        !options
      )
        return;

      const buttons = [...buttonsUp, ...buttonsDown];

      const changeTime = ({ target }) => {
        selectedTime.textContent = `${inputHours.value}:${inputMinutes.value}`;
      };

      inputHours.addEventListener('input', changeTime);
      inputMinutes.addEventListener('input', changeTime);

      buttons.forEach((button) => {
        button.addEventListener('click', () => {
          setTimeout(() => {
            const updatedTime = `${inputHours.value}:${inputMinutes.value}`;
            options.forEach((option, i) => {
              const optionValue = option.getAttribute('value');
              const selectedTimeValue = selectedTime.getAttribute('value');
              if (
                optionValue === selectedTimeValue &&
                option.textContent !== updatedTime
              ) {
                option.textContent = updatedTime;
              }
            });
            selectedTime.textContent = updatedTime;
          }, 100);
        });
      });
    }, 100);

    return () => clearTimeout(timer);
  }, [calendarValue, isOpen]);




  useLayoutEffect(() => {
    if (clearFilter) {
      setCalendarValue('');
      setValue(fieldName, '');
      setDateFormat('');
    }
  }, [clearFilter]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const timeInput = document.querySelector('.rmdp-input');
        if (!timeInput) return;
        const timeInputValue = timeInput.value;
        // if (!timeInputValue.length) {
        //   timeInput.insertAdjacentHTML(
        //     'afterbegin',
        //     '<option style="display: none;" disabled selected value="">' +
        //       dictionary?.choose_time +
        //       '<option>'
        //   );
        //   const options = timeInput.querySelectorAll('option');
        //   timeInput.addEventListener('focus', () => {
        //     console.log(options[0]);
        //     [...options].filter((option) => !option.value);
        //   });
        // }
      }, 300);
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      const dateRightBlock = document.querySelector('.rmdp-calendar + div');
      if (!dateRightBlock) return;
      if (!getValues(fieldName) || !isOpen) {
        dateRightBlock.style.display = 'none';
      } else {
        dateRightBlock.style.display = 'grid';
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [fieldName, isOpen, getValues(fieldName)]);

  // useEffect(() => {
  //   if (receivedValue) {
  //     const convertedDates = receivedValue.map(
  //       (dateStr) => new DateObject(dateStr)
  //     );
  //     setCalendarValue(convertedDates);
  //   }
  // }, [receivedValue]);

  useEffect(() => {
    setCalendarValue(getValues(fieldName));
  }, [getValues(fieldName), fieldName, isOpen]);

  const createDateStr = (year, month, day, hour = '00', minute = '00') => {
    return `${year}-${month}-${day} ${hour}:${minute}`;
  };

  return (
    <Controller
        name={fieldName}
        control={control}
        render={({
                   field: { onChange, name, value },
                   fieldState: { invalid, isDirty }, //optional
                   formState: { errors }, //optional, but necessary if you want to show an error message
                 }) =>  (
        <Box
          sx={(theme) => ({
            width: '100px',
            '.MuiBox-root': {
              width: 'auto',
              boxShadow: 'none',
              border: `1px solid ${theme.palette.basicColor.color900}`,
            },
          })}
        >
          {/* {console.log('calendarValue', calendarValue)} */}
          {/* {console.log('getValues', getValues(fieldName))} */}
          {isOpen && (
              <Box
                  component={Calendar}
                  locale={locales[language]}
                  value={calendarValue}
                  sx={calendarStyles}
                  ref={calendarRef}
                  datePanel
                  onChange={(dates) => {
                    onChange(dates); // Update the form's state
                  }}
                  range
                  numberOfMonths={screenSize === 'xs' ? 1 : 2}
                  closeCalendarOnChange={false}
                  format={' DD.MM.YYYY HH:mm'}
                  language={language}
                  plugins={[
                    <TimePicker
                        position="bottom"
                        placeholder="Select time"
                        format="HH:mm"
                        hideSeconds
                        names={{
                          selectTime: 'Select time',
                          close: 'Close',
                        }}
                    />,
                    <DatePanel markFocused position="right" sort="date" />
                  ]}
              />
          )}
          <TextField
              ref={textFieldRef}
              label={placeholder}
              value={getValues(fieldName) || ''}
              onFocus={() => setIsOpen(true)}
              type="text"
              autoComplete="off"
              sx={{
                '.MuiInputBase-input': {
                  cursor: 'pointer',
                },
                '& .MuiTextField-root': {
                  cursor: 'pointer',
                },
                '& .MuiInputBase-root:hover': {
                  cursor: 'pointer',
                },
              }}
          />
        </Box>
      )}
    />
  );
};

export default MyDatePicker;
