import {useContext} from 'react';

import {Toolbar, Box} from '@mui/material';

import FilterAccordion from '../Filter/FilterAccordion';
import MyButton from '../ui/Button/MyButton';
import SearchBar from '../SearchBar/SearchBar';
import TblPageDisplay from '../Table/TablePageDisplay';

import {PageContext} from '../../pages/Page';

const MyToolbar = ({
                       changeContentButton = true,
                       buttonHandler,
                       myButtonTitle,
                       buttonSx = {},
                       toolbarSx = {},
                       buttonClass,
                       paginationData,
                       filter,
                       search,
                       sx = {},
                       moveToLeft,
                       parametr,
                       removedParametr,
                       withoutSearchQueryString = false,
                       setSearch,
                       infobar = '',
                       disabled = false,
                   }) => {
    const {dictionary, content, screenSize} = useContext(PageContext);

    return (
        <Toolbar
            sx={{
                ...toolbarSx,
                alignItems:
                    search || filter || changeContentButton ? 'normal' : 'flex-end',
                flexDirection: search ? 'column' : 'row',
            }}
        >
            {(filter || changeContentButton) && (
                <Box sx={{flexDirection: screenSize === 'xs' && content !== 'channelsList' && 'row-reverse'}}>
                    {filter && (
                        <FilterAccordion disabled={disabled} moveToLeft={moveToLeft}/>
                    )}
                    {search && (screenSize === 'xs' && content === 'visitorsList') && (
                        <SearchBar
                            placeholder={search}
                            withoutSearchQueryString={withoutSearchQueryString}
                            setSearch={setSearch}
                            //   disabled={disabled}
                        />
                    )}
                    {changeContentButton && (
                        <MyButton
                            title={myButtonTitle ? myButtonTitle : dictionary?.create}
                            sx={{...buttonSx, button: {width: screenSize === 'xs' ? '100px !important' : undefined}}}
                            buttonHandler={buttonHandler}
                            buttonClasses={buttonClass ? buttonClass : 'btn--primary'}
                        />
                    )}
                </Box>
            )}
            {(search || paginationData) && (
                <Box
                    sx={{
                        ...sx,
                        justifyContent: search || content === 'visitorsList' ? 'space-between' : 'flex-end',
                        alignItems: filter && !search ? 'flex-end' : 'normal',
                        maxWidth: filter && !search ? '220px !important' : 'inherit',
                    }}
                >
                    {search && (screenSize !== 'xs' || content !== 'visitorsList') && (
                        <SearchBar
                            placeholder={search}
                            content={content}
                            withoutSearchQueryString={withoutSearchQueryString}
                            setSearch={setSearch}
                            //   disabled={disabled}
                        />
                    )}
                    {paginationData && (
                        <TblPageDisplay
                            data={paginationData}
                            sx={{
                                display: screenSize === 'xs' && content === 'visitorsList' ? 'flex' : undefined,
                                justifyContent: screenSize === 'xs' && content === 'visitorsList' ? 'space-between !important' : 'flex-end',
                                maxWidth: screenSize === 'xs' && content === 'visitorsList' ? '100%' : undefined,
                                position: filter && !search ? 'absolute' : 'relative',
                                bottom: filter && !search ? 5 : 0,
                            }}
                            parametr={parametr}
                            removedParametr={removedParametr}
                        />
                    )}
                    {infobar}
                </Box>
            )}
        </Toolbar>
    );
};

export default MyToolbar;
