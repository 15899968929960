import {useContext} from 'react';

import {TableRow, TableCell, Typography} from '@mui/material';
import {FlexBox, theme} from '../../../mui/theme';

import {ReactComponent as SadSmile} from '../../../assets/icons/sad-smile.svg';

import {PageContext} from '../../../pages/Page';
import {PageContainerContext} from '../../../pages/PageContainer';

const EmptyListAlertText = ({sx = {}}) => {
    const {dictionary} = useContext(PageContext);
    const {queryParams} = useContext(PageContainerContext);

    return (
        <TableRow
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                width: '100%',
                minHeight: 267,
                [theme.breakpoints.down(550)]: {
                    left: 0,
                },
                ...sx,
            }}
        >
            <TableCell>
                <FlexBox sx={{
                    [theme.breakpoints.down(550)]: {
                        flexDirection: 'column'
                    },
                }} className="row-20" alignItems="center">
                    <Typography sx={{fontSize: '18px!important', fontWeight: 600}}>
                        {queryParams.size > 0 ? dictionary?.nothing_found : dictionary?.no_data}
                    </Typography>
                    <SadSmile/>
                </FlexBox>
            </TableCell>
        </TableRow>
    );
};

export default EmptyListAlertText;
