import {useContext} from 'react';

import {Box} from '@mui/material';

import MyToolbar from '../../../components/Toolbar/MyToolbar';
import ChannelsListTemplate from './ChannelsListTemplate';

import {
    setSitesTableHeadings as setSitesHeadCells,
    // channelsTableHeadings as channelsHeadCells,
} from '../../../data/tableHeadingsData';

import {PageContext} from '../../Page';
import MyButton from "../../../components/ui/Button/MyButton";

const ChannelsList = ({
                          sitesList,
                          activeSites,
                          maxActiveSites,
                          sitesSuccess,
                          sitesPaginationData,
                          channelsList,
                          channelsSuccess,
                          channelsPaginationData,
                          changeContentHandler,
                          copy,
                          copiedText,
                          navigate,
                          alertMessageContent,
                      }) => {
    const {dictionary, screenSize} = useContext(PageContext);

    const channelsHeadCells = () => {
        return setSitesHeadCells(dictionary).map((cell) => {
            if (cell.id === 'domain') {
                return {...cell, title: dictionary?.channel};
            } else {
                return cell;
            }
        });
    };

    const copyTextHandler = (text) => {
        if (copiedText === undefined) {
            copy(text);
            alertMessageContent(dictionary?.data_copied, 'success');
        }
    };

    return (
        <>
            <MyToolbar
                myButtonTitle={dictionary?.add_channel}
                useButtonHandler={true}
                buttonSx={{maxWidth: screenSize === 'xs' ? '100%' : '200px !important'}}
                paginationData={screenSize !== 'xs' ? sitesPaginationData : ''}
                buttonHandler={() => {
                    changeContentHandler('addChannel');
                    navigate('/channels#add');
                }}
            />
            <Box sx={{marginBottom: 3.75}}>
                <ChannelsListTemplate
                    list={sitesList}
                    activeSites={activeSites}
                    maxActiveSites={maxActiveSites}
                    listContent={'sites'}
                    setHeadCells={setSitesHeadCells}
                    isSuccess={sitesSuccess}
                    paginationData={screenSize !== 'xs' ? sitesPaginationData : ''}
                    copyTextHandler={copyTextHandler}
                    content={'editSite'}
                    path={'/channels/site-form#'}
                />
            </Box>
            <MyToolbar
                paginationData={screenSize !== 'xs' ? channelsPaginationData : ''}
                changeContentButton={false}
                parametr={'second_limit'}
                removedParametr={'second_page'}
            />
            <Box>
                <ChannelsListTemplate
                    list={channelsList}
                    listContent={'channels'}
                    setHeadCells={channelsHeadCells}
                    isSuccess={channelsSuccess}
                    paginationData={screenSize !== 'xs' ? channelsPaginationData : ''}
                    content={'editChannel'}
                    path={'/channels/channel-form#edit'}
                />
            </Box>
        </>
    );
};

export default ChannelsList;
