// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion__item {
  margin-bottom: 2px;
}

.accordion__item:nth-child(even) {
  background-color: #DFE2EE !important;
}

.accordion__item:nth-child(even) > .accordion__header {
  background-color: #DFE2EE !important;
}

.accordion__item:nth-child(odd) {
  background-color: #F5F6FA !important;
}

.accordion__item:nth-child(odd) > .accordion__header {
  background-color: #F5F6FA !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/mobileStyles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACG,oCAAA;AACH;;AAEA;EACE,oCAAA;AACF","sourcesContent":[".accordion__item {\n  margin-bottom: 2px;\n}\n\n.accordion__item:nth-child(even) {\n  background-color: #DFE2EE !important;\n\n}\n.accordion__item:nth-child(even)  > .accordion__header{\n  background-color: #DFE2EE !important;\n}\n\n.accordion__item:nth-child(odd) {\n   background-color: #F5F6FA !important;\n\n }\n.accordion__item:nth-child(odd)  > .accordion__header{\n  background-color: #F5F6FA !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
