import React, {
    useCallback,
    useRef,
    useState,
    useContext,
    memo,
    useMemo,
} from 'react';
import {Box, Avatar, Typography, AvatarGroup} from '@mui/material';
import styled from '@emotion/styled';

import {useOnClickOutside} from '../../hooks/useOnClickOutside';

import {dateConverter, convertBytes} from '../../helpers/functions';

import {ReactComponent as FileIcon} from '../../assets/icons/file.svg';
import {ReactComponent as BookmarkIcon} from '../../assets/icons/add-bookmark.svg';
import {ReactComponent as RemoveBookmarkIcon} from '../../assets/icons/remove-bookmark.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';

import {PageContext} from '../../pages/Page';

const MessageStyled = styled(Box)(({theme, isMine, sx}) => ({
    position: 'relative',
    overflow: 'initial!important',
    display: 'flex',
    height: 'auto!important',
    maxWidth: '496px',
    width: 'fit-content',
    minHeight: 'auto!important',
    maxHeight: 'none!important',
    flexDirection: 'column',
    padding: `${theme.spacing(0.75)} ${theme.spacing(1.25)} ${theme.spacing(
        0.5
    )}!important`,
    marginLeft: isMine ? 'auto' : '0',
    borderRadius: '5px',
    fontSize: '14px!important',
    flexShrink: 0,
    lineHeight: 'initial!important',
    color: `${theme.palette.basicColor.main}!important`,
    '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5),
    },
    ...sx,
}));

export const Message = ({
                            type,
                            isShort,
                            role,
                            isTheSameAuthor,
                            className,
                            message,
                            language,
                            disabled,
                            editMessage,
                            isLast,
                            saveMessage,
                            unsaveMessage,
                            deleteMessage,
                            setIsEditing,
                            isMine = false,
                            isSingle = false,
                        }) => {
    const {dictionary, screenSize} = useContext(PageContext);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const messageRef = useRef();
    useOnClickOutside(messageRef, () => {
        if (isContextMenuOpen) {
            setIsContextMenuOpen(false);
            setIsEditing(false);
        }
    });
    const {created_at, updated_at} = message;

    const openFileHandler = useCallback(
        () => window.open(message?.attachments[0]?.url),
        [message]
    );

    const editMessageHandler = useCallback(() => {
        setIsContextMenuOpen(false);
        setIsEditing(true);
        editMessage(message);
    }, [message, editMessage, setIsEditing]);

    const deleteMessageHandler = useCallback(() => {
        setIsContextMenuOpen(false);
        deleteMessage(message.id);
    }, [message, deleteMessage]);

    const saveMessageHandler = useCallback(() => {
        setIsContextMenuOpen(false);
        saveMessage(message.id);
    }, [message, saveMessage]);

    const unsaveMessageHandler = useCallback(() => {
        setIsContextMenuOpen(false);
        unsaveMessage(message.id);
    }, [message, unsaveMessage]);

    return (
        <>
            {message?.user?.first_name}
            <MessageStyled
                ref={messageRef}
                lang={language}
                onContextMenu={(e) => {
                    if (
                        disabled &&
                        isMine &&
                        !(role === 'client' && type === 'support')
                    ) {
                        e.preventDefault();
                        setIsContextMenuOpen(true);
                    }
                }}
                sx={{
                    marginLeft: isMine ? 'auto' : '0',
                    bgcolor: isMine ? '#DAECF9' : 'basicColor.color100',
                    minWidth: isShort ? '60px' : (screenSize === 'xs' ? '180px' : '200px'),
                    maxWidth: screenSize === 'xs' ? '200px !important' : '300px',
                    '-webkit-hyphens': 'auto',
                    hyphens: 'auto',
                    wordBreak: 'initial',
                    '&::before': {
                        content:
                            (!isSingle && !isMine) || (!isSingle && isMine) ? '""' : 'none',
                        position: 'absolute',
                        bottom: '0',
                        right: isMine ? '-10px' : 'auto',
                        left: !isMine ? '-10px' : 'auto',
                        width: '0',
                        height: '0',
                        borderStyle: 'solid',
                        borderWidth: '0px 18px 19px 19px',
                        borderRightColor: 'transparent',
                        borderLeftColor: 'transparent',
                        borderBottomColor: isMine ? '#DAECF9' : 'basicColor.color100',
                        borderRadius: '5px',
                        zIndex: 0,
                    },
                    img: {
                      maxWidth: screenSize === 'xs' ? '180px !important' : '99%',
                    },
                    p: {
                        margin: '0',
                    },
                }}
                className={className}
            >
                {isContextMenuOpen &&
                    isMine &&
                    ((message?.attachments &&
                            message?.attachments[0]?.type === 'image') ||
                        message?.text) && (
                        <MessageContextMenu
                            canEdit={
                                !message?.attachments &&
                                Date.now() - new Date(created_at) < 1000 * 60 * 10 &&
                                !(role === 'client' && type === 'support')
                            }
                            isSaved={message?.saved}
                            canSave={
                                !message?.attachments && role !== 'admin' && type !== 'support'
                            }
                            onSave={
                                message?.saved ? unsaveMessageHandler : saveMessageHandler
                            }
                            onDelete={deleteMessageHandler}
                            onEdit={editMessageHandler}
                            canDelete={!(role === 'client' && type === 'support')}
                        />
                    )}
                {message?.text ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: message?.text,
                        }}
                    />
                ) : message?.attachments[0]?.type === 'image' ? (
                    <Box
                        sx={{
                            display: 'block',
                            width: '300px',
                            height: 'auto',
                            maxHeight: '300px',
                            marginBottom: '4px',
                            borderRadius: '4px',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8,
                            },
                        }}
                        src={
                            message?.attachments[0]?.type === 'image'
                                ? message?.attachments[0]?.url
                                : null
                        }
                        alt={message?.name}
                        component="img"
                        onClick={() => {
                            openFileHandler();
                            setIsContextMenuOpen(false);
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                        }}
                    >
                        <Avatar
                            onClick={
                                !isContextMenuOpen ? openFileHandler : deleteMessageHandler
                            }
                            sx={{
                                width: '44px',
                                height: '44px',
                                bgcolor: !isContextMenuOpen
                                    ? 'basicColor.main'
                                    : 'secondaryColor.main',
                                cursor: 'pointer',
                                'svg path': {
                                    fill: isContextMenuOpen && 'white',
                                },
                            }}
                        >
                            {isContextMenuOpen ? <DeleteIcon fill="red"/> : <FileIcon/>}
                        </Avatar>
                        <Box>
                            <Typography component={'div'} variant="body2">
                                {message?.attachments[0]?.file?.name}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{width: '100%', fontSize: '12px', lineHeight: '20px'}}
                                color="basicColor.color700"
                            >
                                {convertBytes(message?.attachments[0]?.file?.size, dictionary)}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <Box
                    sx={{position: 'relative', zIndex: 0, ml: 'auto', fontSize: 10}}
                    color="basicColor.color700"
                >
                    {new Date(created_at).toLocaleString() ===
                    new Date(updated_at).toLocaleString()
                        ? dateConverter(created_at, 'time').slice(0, -3)
                        : `${dictionary?.edited} ${dateConverter(updated_at, 'time').slice(
                            0,
                            -3
                        )}`}
                </Box>
            </MessageStyled>
            {isMine && (isLast || !isTheSameAuthor) && (
                <MessageAuthor avatar={message?.user?.avatar}/>
            )}
        </>
    );
};

const MessageAuthor = memo(({avatar}) => (
    <Avatar
        sx={{
            width: '32px',
            height: '32px',
            marginLeft: 'auto',
            marginTop: '4px',
            marginBottom: '20px',
        }}
        key={avatar}
        src={avatar}
    />
));

const MessageContextMenuStyled = styled(Box)({
    position: 'absolute',
    inset: 0,
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    borderRadius: '5px',
    backgroundColor: 'rgba(218, 236, 249, .6)',
});

const MessageContextMenu = ({
                                onEdit,
                                onDelete,
                                onSave,
                                canDelete = false,
                                canEdit = false,
                                canSave = false,
                                isSaved = false,
                            }) => {
    return (
        <MessageContextMenuStyled>
            {canSave && (
                <ContextMenuButton
                    component={isSaved ? RemoveBookmarkIcon : BookmarkIcon}
                    onClick={onSave}
                />
            )}
            {canEdit && <ContextMenuButton component={EditIcon} onClick={onEdit}/>}
            {canDelete && (
                <ContextMenuButton component={DeleteIcon} onClick={onDelete}/>
            )}
        </MessageContextMenuStyled>
    );
};

const ContextMenuButtonStyled = styled(Box)({
    display: 'flex',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    '&:hover': {
        opacity: 0.8,
    },
});

const ContextMenuButton = ({onClick, component}) => (
    <ContextMenuButtonStyled component={component} onClick={onClick}/>
);
