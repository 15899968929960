import React, {
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
    useContext,
    useMemo,
} from 'react';

import {Box, Divider, Button, TextField} from '@mui/material';
import styled from '@emotion/styled';

import {CustomToolbar, TextEditor} from '../../components/TextEditor/TextEditor';

import {ReactComponent as StarIcon} from '../../assets/icons/star.svg';

import {
    // usePostMessageMutation,
    usePostMessageFileMutation,
    useFindSavedMessageMutation,
    useFindCommandQuery,
    useVisitorContactsRequestMutation,
} from '../../api/clientPartApi/apiDialoguesSlice';

import {
    usePostMessageMutation,
    useEditMessageMutation,
} from '../../api/clientPartApi/apiCommonDialogs';

import {socket} from '../../pages/Page';

import {ReactComponent as ClipIcon} from '../../assets/icons/clip.svg';
import {ReactComponent as SendIcon} from '../../assets/icons/send-message.svg';

import {ACCEPTED_FILE_TYPES, disallowedKeys} from '../../helpers/variables';
import {debounce} from '../../helpers/functions';
import {scrollToElement} from '../../helpers/functions';

import {PageContext} from '../../pages/Page';
import {theme} from "../../mui/theme";

const MAX_AVAILABLE_LETTERS = 500;
const TYPING_TIMER = 2000;
const REPLACER = /<(.|\n)*?>/g;

const keysException = (e) =>
    !disallowedKeys.includes(e.key) &&
    !(e.keyCode === 82 && e.ctrlKey) &&
    !(e.keyCode === 86 && e.ctrlKey) &&
    !(e.keyCode === 88 && e.ctrlKey) &&
    !(e.keyCode === 90 && e.ctrlKey) &&
    !(e.keyCode === 89 && e.ctrlKey) &&
    !(e.keyCode === 65 && e.ctrlKey);

const setCursorEndPosition = (
    element = document.querySelector('.ql-editor:not(.message)')
) => {
    setTimeout(() => {
        if (element) {
            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(element, 1);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }, 0);
};

const TextareaStyled = styled('textarea')(({_, isChatActive}) => ({
    border: 'none',
    width: '100%',
    height: '92px',
    padding: '14px 20px',
    resize: 'none',
    outline: 'none',
    fontSize: '14px',
    fontFamily: 'inherit',
    '&[disabled]': {
        cursor: 'default',
        backgroundColor: 'initial',
    },
}));

export const ChatFooter = ({
                               chatId,
                               role,
                               type,
                               editedMessageId,
                               defaultValue,
                               onSend = () => {
                               },
                               isSupport = false,
                               isEditing = false,
                               setIsEditing = () => {
                               },
                               isChatActive = true,
                           }) => {
    const {setInDialogue, dictionary, screenSize} = useContext(PageContext);
    const inputFileRef = useRef(null);
    const commandInputRef = useRef(null);
    const [lettersCount, setLettersCount] = useState(0);
    const [text, setText] = useState('');
    const [isWriting, setIsWriting] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isCommand, setIsCommand] = useState(false);
    const [currentMessageContainerHeight, setCurrentMessageContainerHeight] =
        useState('130px');
    const [isSavedShow, setIsSavedShow] = useState(false);

    const [postMessage] = usePostMessageMutation();
    const [editMessage] = useEditMessageMutation();
    const [visitorContactsRequest] = useVisitorContactsRequestMutation();

    useEffect(() => {
        if (defaultValue && isEditing) {
            setText(defaultValue);
            setLettersCount(defaultValue.replace(REPLACER, '').length);
        }
    }, [defaultValue, isEditing]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isChatActive) {
                setCursorEndPosition();
            }
        }, 0);
        return () => {
            clearTimeout(timer);
        };
    }, [defaultValue]);

    useEffect(() => {
        const pasteHandler = (e) => {
            const clipboardTextLength = e.clipboardData.getData('Text').length;
            if (lettersCount + clipboardTextLength > MAX_AVAILABLE_LETTERS) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        };
        document.addEventListener('paste', pasteHandler);
        return () => {
            document.removeEventListener('paste', pasteHandler);
        };
    }, [lettersCount]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isWriting) {
                setIsWriting(false);
                /* setInDialogue(false);*/
                socket.emit(`${!isSupport ? 'dialog' : 'support'}.printing.send`, {
                    status: 'end',
                    id: chatId,
                });
            }
        }, TYPING_TIMER);

        const clickHandler = (e) => {
            if (
                e.target.closest('.ql-editor') ||
                e.target.classList.contains('saved-message')
            ) {
                setIsFocused(true);
            } else {
                setIsFocused(false);
                setIsWriting(false);
                socket.emit(`${!isSupport ? 'dialog' : 'support'}.printing.send`, {
                    status: 'end',
                    id: chatId,
                });
            }
        };
        const escapeHandler = (e) => {
            if (e.key === 'Escape') {
                setIsCommand(false);
                setText((prev) => prev.replace('*', '').trim());
                if (isEditing) {
                    setIsEditing(false);
                    setText('');
                } else if (!isSupport) {
                    document.querySelector('.ql-editor:not(.message)').focus();
                }
            }
        };

        document.addEventListener('click', clickHandler);
        document.addEventListener('keydown', escapeHandler);
        return () => {
            setTimeout(timer);
            document.removeEventListener('click', clickHandler);
            document.removeEventListener('keydown', escapeHandler);
        };
    }, [isFocused, isWriting]);

    useEffect(() => {
        const resizeHandler = () => {
            if (window.innerWidth > 1240) {
                setCurrentMessageContainerHeight('130px');
            } else {
                setCurrentMessageContainerHeight('82px');
            }
        };
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {
        const keyDownHandler = (e) => {
            if (isFocused) {
                if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
                    e.preventDefault();
                    sendMessageHandler();
                    // if (isSupport) {
                    //   e.preventDefault();
                    // }
                    // } else if (e.ctrlKey && e.key === 'Enter' && isSupport) {
                    //   // (event.key === 'Enter' && event.ctrlKey) || (event.key === 'Enter' && event.shiftKey)
                    //   // setText((prev) => `${prev}\n`);
                    //   setText((prev) => {
                    //     const cursorPosition = e.target.selectionStart;
                    //     const textBefore = prev.substring(0, cursorPosition);
                    //     const textAfter = prev.substring(cursorPosition);
                    //     setTimeout(() => {
                    //       e.target.selectionEnd = cursorPosition + 1;
                    //     }, 0);
                    //     return `${textBefore}\n${textAfter}`;
                    //   });
                }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [text, isFocused, isEditing, defaultValue]);

    const onChangeHandler = useCallback(
        (value) => {
            if (value.replace(REPLACER, '')[0] === '*') {
                setIsCommand(true);
                setTimeout(() => {
                    commandInputRef.current.focus();
                }, 10);
            } else {
                setIsCommand(false);
            }
            if (
                value.replace(REPLACER, '').length > 5 &&
                value.replace(REPLACER, '').length < 15
            ) {
                setIsSavedShow(true);
            } else {
                setIsSavedShow(false);
            }
            setText(value);
            if (value && !isWriting) {
                socket.emit(`${!isSupport ? 'dialog' : 'support'}.printing.send`, {
                    status: 'start',
                    id: chatId,
                });
            }
            setIsFocused(true);
            setIsWriting(true);
            setInDialogue(true);
            setLettersCount(value.replace(REPLACER, '').length);
        },
        [isWriting, isChatActive]
    );

    const lettersLimiter = useCallback(
        (e) => {
            const clearText = text.replace(REPLACER, '');

            const isAllowed = keysException(e);

            if (clearText.length > MAX_AVAILABLE_LETTERS - 1 && isAllowed) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        },
        [text]
    );

    const fileUploadHandler = useCallback((data) => {
        postMessage({
            id: chatId,
            file: data,
            role,
            type,
        });
    }, []);

    const keyDownHandler = useCallback((e) => {
        if (e.ctrlKey && e.key === 'Enter' && isSupport) {
            setText((prev) => {
                const cursorPosition = e.target.selectionStart;
                const textBefore = prev.substring(0, cursorPosition);
                const textAfter = prev.substring(cursorPosition);
                setTimeout(() => {
                    e.target.selectionEnd = cursorPosition + 1;
                    e.target.scrollTop = e.target.scrollHeight;
                }, 0);
                return `${textBefore}\n${textAfter}`;
            });
        }
    }, []);

    const sendMessageHandler = useCallback(() => {
        if (!text.replace(REPLACER, '').replace(/\s/g, '').length) return;
        let encodedText = encodeURIComponent(text);
        onSend(true);
        if (isEditing) {
            if (defaultValue !== text) {
                editMessage({
                    id: chatId,
                    text: encodedText,
                    role,
                    type,
                    messageId: editedMessageId,
                });
            }
        } else {
            postMessage({
                id: chatId,
                text: encodedText,
                role,
                type,
            }).then(() => {
                onSend(false);
            });
        }
        setText('');
        setIsSavedShow(false);
        setIsEditing(false);
    }, [text, isEditing, defaultValue]);

    useEffect(() => {
        const editor = document.querySelector('.ql-editor:not(.message)');
        if (editor && !isChatActive) {
            editor.setAttribute('contenteditable', 'false');
        } else if (editor) {
            editor.setAttribute('contenteditable', 'true');
        }
    }, [
        isChatActive,
        text,
        isEditing,
        defaultValue,
        isSupport,
        isSavedShow,
        isCommand,
    ]);
    return (
        <Box  className="chat-footer">
            <FileUploader onUpload={fileUploadHandler} ref={inputFileRef}/>
            <Divider/>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    pointerEvents: isChatActive ? 'all' : 'none',
                    opacity: isChatActive ? '1' : '0.6',
                }}
            >
                {!isSupport && (
                    screenSize !== 'xs' ? (
                        <Box
                            sx={{zIndex: 1, position: 'absolute', right: '20px', top: '10px'}}
                        >
                            {`${lettersCount}/${MAX_AVAILABLE_LETTERS}`}
                        </Box>
                    ) : (
                        <></>
                    )
                )}
                {isSupport ? (
                    <TextareaStyled
                        multiline
                        ref={(ref) => ref && ref.focus()}
                        minRows={2}
                        placeholder={
                            dictionary?.your_message ? `${dictionary?.your_message}...` : ''
                        }
                        disabled={!isChatActive}
                        value={text}
                        onChange={(e) => {
                            if (
                                e.target.value.replace(REPLACER, '').length <
                                MAX_AVAILABLE_LETTERS &&
                                type === 'dialogs'
                            ) {
                                return;
                            }
                            onChangeHandler(e.target.value);
                        }}
                        onKeyDown={keyDownHandler}
                    />
                ) : (
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: screenSize === 'xs' ? '60px' : '170px',
                            width: '100%',
                            paddingBottom: '8px',
                        }}
                    >
                        <TextEditor
                            withToolbar={true}
                            onKeyDown={lettersLimiter}
                            key={defaultValue + isEditing.toString()}
                            placeholder={
                                dictionary?.your_message ? `${dictionary?.your_message}...` : ''
                            }
                            value={text}
                            defaultValue={text}
                            onChange={onChangeHandler}
                            disabled={!isChatActive}
                            isFullHeight={true}
                            toolbarSx={{
                                display: screenSize === 'xs' ? 'none !important' : undefined,
                                '& .MuiBox-root': {
                                    width: '100%',
                                },
                                '&.ql-snow': {
                                    borderRadius: '0',
                                    border: 'none!important',
                                    paddingRight: '92px!important',
                                },
                            }}
                            editorSx={{
                                '.ql-editor': {
                                    overflowY: 'auto',
                                    maxHeight: currentMessageContainerHeight,
                                    minHeight:
                                        role === 'client' && type === 'dialogs' && isSavedShow
                                            ? '0px'
                                            : currentMessageContainerHeight,
                                    wordBreak: 'break-all',
                                },
                                '.ql-container.ql-snow': {
                                    border: 'none',
                                    minHeight: '0px',
                                    paddingRight: '92px',
                                },
                                height: '100%',
                                minHeight: '134px',
                            }}
                        />
                        {role === 'client' && type === 'dialogs' && isCommand && (
                            <Commands
                                screenSize={screenSize}
                                setText={setText}
                                setIsCommand={setIsCommand}
                                ref={commandInputRef}
                                setLetters={setLettersCount}
                            />
                        )}
                        {role === 'client' && type === 'dialogs' && isSavedShow && (
                            <SavedMessages
                                screenSize={screenSize}
                                key={text}
                                text={text.replace(REPLACER, '')}
                                setText={setText}
                                setLetters={setLettersCount}
                                setIsSavedShow={setIsSavedShow}
                            />
                        )}
                    </Box>
                )}
                <Box
                    sx={{
                        position: 'absolute',
                        right: '20px',
                        top: screenSize === 'xs' ? '24px' : (isSupport ? '14px' : '54px'),
                        bottom: isSupport ? '14px' : '20px',
                        display: 'flex',
                        flexDirection: screenSize === 'xs' ? 'row' : 'column',
                        justifyContent: 'space-between',
                        gap: '12px',
                    }}
                >
                    <ChatButton
                        disabled={!isChatActive}
                        onClick={() => {
                            inputFileRef.current.click();
                        }}
                    >
                        <ClipIcon/>
                    </ChatButton>
                    <ChatButton disabled={!isChatActive} onClick={sendMessageHandler}>
                        <SendIcon/>
                    </ChatButton>
                </Box>
            </Box>
        </Box>
    );
};

const ChatButton = ({
                        children, disabled = false, onClick = () => {
    }
                    }) => (
    <Button
        onClick={onClick}
        disabled={disabled}
        sx={{
            cursor: !disabled ? 'pointer' : 'default',
            opacity: !disabled ? '1' : '0.2',
            '&:hover': {
                opacity: !disabled ? '0.8' : '0.2',
            },
            '&.MuiButton-root': {
                minWidth: '0',
                padding: '0',
                backgroundColor: !disabled ? 'transparent' : 'initial',
            },
        }}
    >
        {children}
    </Button>
);

const FileUploader = forwardRef(({
                                     onUpload = () => {
                                     }
                                 }, ref) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        onUpload(formData);
    }, [file]);

    const handleChange = useCallback(
        (event) => {
            const selected = event.target.files[0];
            const types = ACCEPTED_FILE_TYPES;

            if (selected && types.includes(selected.type)) {
                setFile(selected);
                setError('');
            } else {
                setFile(null);
                setError('Please select an image file (png or jpeg)');
            }
        },
        [file]
    );

    return (
        <Box>
            <TextField
                sx={{display: 'none'}}
                inputRef={ref}
                type="file"
                onChange={handleChange}
                inputProps={{
                    multiple: false,
                    accept: ACCEPTED_FILE_TYPES.join(', '),
                }}
            />
        </Box>
    );
});

const SavedMessages = ({screenSize, text, setText, setLetters, setIsSavedShow}) => {
    const refMessages = useRef([]);
    const counter = useRef(0);
    const [request, {data: savedMessages}] = useFindSavedMessageMutation();

    const debouncedRequest = useCallback(debounce(request, 1000), []);

    useEffect(() => {
        if (refMessages.current) {
            const keypressHandler = (e) => {
                if (e.keyCode === 40) {
                    // prevent parent block scrolll
                    e.preventDefault();

                    if (
                        refMessages.current.length - 1 >= counter.current ||
                        counter.current === 0
                    ) {
                        refMessages.current[counter.current]?.focus();
                        if (refMessages.current.length - 1 > counter.current) {
                            counter.current++;
                        }
                    }
                } else if (e.keyCode === 38) {
                    // prevent parent block scrolll
                    e.preventDefault();

                    if (
                        refMessages.current.length - 1 >= counter.current &&
                        counter.current >= 0 &&
                        refMessages.current.length !== 1
                    ) {
                        if (refMessages.current.length - 1 >= counter.current) {
                            counter.current--;
                        }
                        refMessages.current[counter.current]?.focus();

                        // if we select first element we don't want the focus to stay on him, after we pressed down arrow button
                        if (counter.current === 0) {
                            counter.current++;
                        }
                    }
                }
            };

            debouncedRequest(text);

            document.addEventListener('paste', keypressHandler);
            document.addEventListener('keydown', keypressHandler);
            return () => {
                document.removeEventListener('paste', keypressHandler);
                document.removeEventListener('keydown', keypressHandler);
            };
        }
    }, [text]);

    const selectMessage = (message) => {
        setText(message.text);
        setLetters(message.text.replace(REPLACER, '').length);
        setCursorEndPosition();
        setIsSavedShow(false);
    };

    if (!savedMessages || savedMessages?.data?.answers?.length === 0) {
        return null;
    }

    return (
        <Box
            sx={{
                [theme.breakpoints.between(1000,1300)]: {
                    maxWidth: '350px',
                },
                overflowY: 'auto',
                maxHeight: '80px',
                padding: '4px 14px',
                marginRight: '92px',
                marginTop: '-12px',
                zIndex: '1',
            }}
        >
            {savedMessages?.data?.answers.map((message) => (
                <Box
                    className="saved-message"
                    key={message.id}
                    ref={(el) => {
                        if (el && !refMessages.current.includes(el)) {
                            refMessages.current.push(el);
                        }
                    }}
                    tabIndex={1}
                    typography="body2"
                    sx={{
                        color: 'basicColor.color700',
                        cursor: 'pointer',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxWidth: '40vw',

                        '&:hover': {
                            color: 'basicColor.colorBlack',
                        },
                        '&:focus': {
                            outline: 'none',
                            color: 'basicColor.colorBlack',
                        },
                    }}
                    onClick={() => {
                        selectMessage(message);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.ctrlKey) {
                            selectMessage(message);
                        }
                    }}
                >
                    {message.text.replace(REPLACER, '')}
                </Box>
            ))}
        </Box>
    );
};

const Commands = forwardRef(({screenSize, setText, setIsCommand, setLetters}, ref) => {
    const [commandText, setCommandText] = useState('');
    // const { data: command } = useFindCommandQuery({
    //   text: commandText,
    // });
    const {data: command, request} = useFindCommandQuery({
        text: commandText,
    });

    useEffect(() => {
        if (command?.data) {
            setIsCommand(false);
            setText(command.data?.text);
            setLetters(command.data?.text.replace(REPLACER, '').length);
        }
    }, [command]);

    const commandInputHandler = useCallback((e) => {
        const value = e.target.value;

        debouncedCommandInputHandler(value);
    }, []);

    const debouncedCommandInputHandler = debounce((value) => {
        if (value.length < 1) {
            setIsCommand(false);
            setText('');
            ref.current.focus();
        } else {
            setCommandText(value);
        }
    }, 1000);

    return (
        <Box sx={{maxWidth: screenSize === 'md' ? '200px' : undefined , position: 'absolute', top: '44px', left: '13px'}}>
            <Box
                component={StarIcon}
                sx={{
                    marginRight: '14px',
                    backgroundColor: 'basicColor.colorWhite',
                }}
            />
            <Box
                ref={ref}
                component={'input'}
                type="text"
                typography={'body2'}
                color="secondaryColor.main"
                sx={{
                    border: 'none',
                    outline: 'none',
                }}
                onChange={commandInputHandler}
            />
        </Box>
    );
});
