import { useEffect, useContext, useLayoutEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Grid } from '@mui/material';

import SubmitButton from '../ui/Button/SubmitButton';

import { ReactComponent as ApplyIcon } from '../../assets/icons/checked.svg';

import { createMuiComponent } from '../../mui/functions';

import { PageContext } from '../../pages/Page';
import { PageContainerContext } from '../../pages/PageContainer';
import { FilterContext } from './FilterAccordion';
import {theme} from "../../mui/theme";

const MyForm = createMuiComponent('form', 'MuiForm');

const FilterFormContainer = ({ children, formSubmitHandler, currentQueryParams, formSx = {} }) => {
    const { dictionary } = useContext(PageContext);
    const { queryParams, setQueryParams } = useContext(PageContainerContext);
    const { clearFilter, setClearFilter, showButton, setShowButton, allFilterParams } =
        useContext(FilterContext);

    const methods = useForm();
    const { reset, handleSubmit, watch } = methods;

    useEffect(() => {
        const subscription = watch((value) => {
            const someValueIsTrue = Object.values(value).some(
                (value) => value && (value?.length || value?.id)
            );

            if (Object.keys(value).length !== 0 && someValueIsTrue) {
                setShowButton(true);
            } else {
                if (!queryParams.size || !allFilterParams.some((param) => queryParams.has(param))) {
                    setShowButton(false);
                }
            }
        });

        if (!queryParams.size || !allFilterParams.some((param) => queryParams.has(param))) {
            setShowButton(false);
        }

        return () => subscription.unsubscribe();
    }, [watch, queryParams]);

    useLayoutEffect(() => {
        if (clearFilter) {
            reset();
            setClearFilter(false);

            if (queryParams.size) {
                setQueryParams(
                    (params) => {
                        currentQueryParams.forEach((param) => {
                            if (queryParams.get(param)) {
                                params.delete(param);
                            }
                        });
                        return params;
                    }
                    // { replace: true }
                );
            }
        }
    }, [clearFilter]);

    return (
        <FormProvider {...methods}>
            <MyForm className="form" onSubmit={handleSubmit(formSubmitHandler)}>
                <Grid
                    container
                    sx={{
                        marginBlock: 3.75,
                        columnGap: 2.5,
                        rowGap: 3.75,
                        '& .MuiGrid-item': {
                            minWidth: 307,
                            maxWidth: 320,
                            width: '100%',
                            [theme.breakpoints.down(550)]: {
                                flexBasis: '100%',
                                maxWidth: '100%',
                            },
                        },
                        ...formSx,
                    }}
                >
                    {children}
                </Grid>
                <SubmitButton
                    sx={{
                        gap: 1,
                        '& svg path, &:hover svg path': {
                            fill: '#ffffff',
                        },
                    }}
                    title={dictionary?.apply}
                    additionalClass={'btn--small'}
                    disabled={showButton ? !showButton : true}
                >
                    <ApplyIcon />
                </SubmitButton>
            </MyForm>
        </FormProvider>
    );
};

export default FilterFormContainer;
