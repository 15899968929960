import {Box, Typography} from '@mui/material';

import GeneralInput from '../FormInputs/GeneralInput';
import PasswordInput from '../FormInputs/PasswordInput';
import TextButton from '../../ui/Button/TextButton';
import MyButton from '../../ui/Button/MyButton';
import SinglePhoneSelect from '../FormSelect/PhoneSelect/SinglePhoneSelect';

import {
    clientAdminRegistrationSchema as adminRegistrationSchema,
    clientRegistrationSchema as registrationSchema,
    clientRegistrationSchemaIsNotRequired as registrationSchemaIsNotRequired,
} from '../../../settings/formSchema';
import {useContext} from "react";
import {PageContext} from "../../../pages/Page";

const RegistrationForm = ({
                              showForm,
                              dictionary,
                              selectedRole,
                              setSelectedRole,
                              changeFormContent,
                              phoneValidationError,
                              setPhoneValidationError,
                              isSubmited,
                          }) => {
    const {screenSize} = useContext(PageContext);
    const buttons = [
        {role: 'adm', title: dictionary?.admin, schema: adminRegistrationSchema},
        {
            role: 'sv',
            title: dictionary?.supervizor,
            schema: registrationSchemaIsNotRequired,
        },
        {
            role: 'op',
            title: dictionary?.operator,
            schema: registrationSchemaIsNotRequired,
        },
    ];

    const showButtonGroup = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 2.5,
                    borderBottom: '1px solid #DAECF9',
                    '& .MuiButtonBase-root:not(:last-of-type)': {marginBottom: 1.125},
                }}
            >
                {buttons.map(({title, role, schema}) => {
                    return (
                        <MyButton
                            sx={{maxWidth:screenSize === 'xs' ? '100% !important' : undefined }}
                            key={role}
                            buttonClasses={'btn--transparent btn--transparent-big'}
                            title={title}
                            buttonHandler={() => {
                                setSelectedRole({role, title});
                                changeFormContent(
                                    'registration',
                                    dictionary?.registration,
                                    dictionary?.register_to_start,
                                    dictionary?.register,
                                    schema
                                );
                            }}
                        />
                    );
                })}
            </Box>
        );
    };

    const showRegistrationForm = () => {
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1.25,
                        width: '100%',
                        padding: '10px 14px',
                        borderRadius: 1.25,
                        backgroundColor: 'basicColor.color100',
                    }}
                >
                    <Typography variant="body2">{selectedRole.title}</Typography>
                    <TextButton
                        title={dictionary?.change_role}
                        buttonHandler={() =>
                            changeFormContent(
                                'initialRegistration',
                                dictionary?.registration,
                                dictionary?.choose_role,
                                ''
                            )
                        }
                    />
                </Box>
                <GeneralInput fieldName={'surname'} label={dictionary?.surname}/>
                <GeneralInput fieldName={'name'} label={dictionary?.first_name}/>
                <GeneralInput fieldName={'patronymic'} label={dictionary?.patronymic}/>
                <GeneralInput fieldName={'email'} label={'Email'}/>
                <SinglePhoneSelect
                    fieldName={'phone'}
                    dropdownWidth={300}
                    phoneValidationError={phoneValidationError}
                    setPhoneValidationError={setPhoneValidationError}
                    isSubmited={isSubmited}
                />
                <PasswordInput
                    fieldName={'registration_password'}
                    label={dictionary?.password}
                />
                {selectedRole.role !== 'adm' && (
                    <GeneralInput
                        fieldName={'code'}
                        label={dictionary?.invitation_code}
                    />
                )}
            </>
        );
    };

    const registrationForm = showRegistrationForm();
    const buttonGroup = showButtonGroup();

    return (
        <>
            {showForm === 'initialRegistration' && buttonGroup}
            {showForm === 'registration' && registrationForm}
        </>
    );
};

export default RegistrationForm;
