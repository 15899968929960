import {createSlice} from '@reduxjs/toolkit';

import {apiSlice} from '../api/apiSlice';

const messagesSlice = createSlice({
    name: 'chatMessages',
    initialState:
        {
            dialogId: null,
            income: [],
            outcome: [],
            dialogsHistory: [],
        },
    reducers: {
        clearMessages: (state) => {
            state.income = [];
            state.outcome = [];
            state.dialogsHistory = [];
        },
        setDialogHistory: (state, action) => {
            const dialog = state.dialogsHistory.find((dialog) => dialog.id === action.payload);
            if (dialog) {
                state.income = dialog.income;
                state.outcome = dialog.outcome;
            }
        },
        excludeDialogHistory: (state, action) => {
            state.dialogsHistory = action.payload.dialogsHistory;
        }

    },

    extraReducers: (builder) => {
        builder
            .addMatcher(
                apiSlice.endpoints.getMessages.matchFulfilled,
                (state, action) => {
                    const page = action?.meta?.arg?.originalArgs?.page;

                    const getMessages = (data) => data?.chat?.messages || data?.client?.chat?.messages || { income: [], outcome: [] };

                    const { income = [], outcome = [] } = getMessages(action.payload.data);

                    const replaceMessages = (existingMessages, newMessages) => {
                        const messageMap = new Map(existingMessages.map(msg => [msg.id, msg]));
                        newMessages.forEach(msg => messageMap.set(msg.id, msg)); // Перезапись, если id совпадает
                        return Array.from(messageMap.values());
                    };

                    if (state.dialogId === null) {
                        state.dialogId = action.payload.data?.id;
                    }

                    if (page > 1) {
                        state.income = replaceMessages(state.income, income);
                        state.outcome = replaceMessages(state.outcome, outcome);

                        state.dialogsHistory = state.dialogsHistory.filter(dialog => dialog.parentId !== action.payload.data.parentId);
                        state.dialogsHistory.push({
                            id: action.meta.arg.originalArgs.id,
                            income: state.income,
                            outcome: state.outcome,
                            parentId: action.payload.data.parentId,
                            page
                        });
                    } else {
                        if (action.payload.data?.id !== state.dialogId) {
                            state.dialogId = action.payload.data?.id;
                            state.income = income;
                            state.outcome = outcome;
                        } else {
                            state.income = replaceMessages(state.income, income);
                            state.outcome = replaceMessages(state.outcome, outcome);
                        }
                    }
                }
            )

            .addMatcher(
                apiSlice.endpoints.deleteMessage.matchFulfilled,
                (state, action) => {
                    const deletedIds = action.meta.arg.originalArgs.messageId;
                    state.income = state.income.filter(
                        (message) => !deletedIds.includes(message.id)
                    );
                    state.outcome = state.outcome.filter(
                        (message) => !deletedIds.includes(message.id)
                    );

                    state.dialogsHistory = state.dialogsHistory.map((dialog) => ({
                        ...dialog,
                        income: dialog.income.filter((msg) => !deletedIds.includes(msg.id)),
                        outcome: dialog.outcome.filter((msg) => !deletedIds.includes(msg.id)),
                    }));
                }
            )
            .addMatcher(
                apiSlice.endpoints.editMessage.matchFulfilled,
                (state, action) => {
                    const updatedMessage = action.meta.arg.originalArgs; // Обновленное сообщение
                    const updateMessageInList = (messages) =>
                        messages.map((message) =>
                            message.id === updatedMessage.messageId
                                ? { ...message, text: decodeURIComponent(updatedMessage.text) }
                                : message
                        );

                    // Обновляем в списке входящих и исходящих сообщений
                    state.income = updateMessageInList(state.income);
                    state.outcome = updateMessageInList(state.outcome);

                    // Обновляем в истории диалогов
                    state.dialogsHistory = state.dialogsHistory.map((dialog) => ({
                        ...dialog,
                        income: updateMessageInList(dialog.income),
                        outcome: updateMessageInList(dialog.outcome),
                    }));
                }
            );
    },
});

export const {clearMessages, setDialogHistory, excludeDialogHistory} = messagesSlice.actions;

export default messagesSlice.reducer;