import { useState, useContext, useEffect } from 'react';

import NavItem from './NavItem';
import { users } from '../../routes/usersData';
import { PageContext } from '../../pages/Page';

import './nav.scss';

const Navbar = () => {
  const { role, pathname, screenSize, active } = useContext(PageContext);

  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    setActiveItem(pathname.replace('/', ''));
  }, [pathname]);

  const renderNavList = () => {
    const currentUser = users.find((user) => role === user.role);


    return currentUser.routeList.map((route) => {
      if (!route.notNavLink) {
        return (
          <NavItem
            key={route.path}
            route={route}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        );
      }
    });
  };

  const navItems = renderNavList();

  return (
    <nav className={`${screenSize === 'md' && !active ? 'nav__md' : 'nav '}`}>
      <ul className="nav__list">{navItems}</ul>
    </nav>
  );
};

export default Navbar;
