import {useState, useContext, useLayoutEffect} from 'react';

import {setLanguage} from '../../reducers/authorizationSlice';

import {Tabs, Tab, Box} from '@mui/material';

import GeneralInput from '../Form/FormInputs/GeneralInput';
import {CustomTabPanel, a11yProps} from './TabPanel';

import {FourthStepContent} from '../../pages/clientPartPages/Channels/Site/FourthStep';
import {FifthStepContent} from '../../pages/clientPartPages/Channels/Site/FifthStep';
import {NotificationsMultiLanguageFields} from '../../pages/adminPartPages/Notifications/NotificationsForm';

import {PageContext} from '../../pages/Page';
import {FormContainerContext} from '../Form/FormContainer';

const languagesTemplate = [
    {language: 'uk', label: 'UA'},
    {language: 'en', label: 'EN'},
    {language: 'ru', label: 'RU'},
];

const LanguageTabs = ({
                          sx,
                          label,
                          tabsClass,
                          receivedValue,
                          onlyTabs = false,
                          multiline = false,
                          fieldNamePrefix = '',
                          maxNumOfCharacters,
                          tabsSx = {},
                          tabContent,
                          savedLanguages,
                          languageTabsStyle,
                          ...rest
                      }) => {
    const {clientCheck, dispatch, changeLanguage, language} = useContext(PageContext);
    const {usedFormValidationErrors} = useContext(FormContainerContext);

    const [value, setValue] = useState(0);
    const [languages, setLanguages] = useState([]);

    useLayoutEffect(() => {
        if (onlyTabs && language) {
            languagesTemplate.forEach((item, index) => {
                if (language === item.language) {
                    setValue(index);
                }
            });
        }
    }, [language]);

    useLayoutEffect(() => {
        if (savedLanguages && savedLanguages.length !== 0) {
            const newLanguages = languagesTemplate
                .map((language) => {
                    if (savedLanguages.includes(language.language)) {
                        return language;
                    }
                })
                .filter((language) => language);

            setLanguages(newLanguages);
        } else {
            setLanguages(languagesTemplate);
        }
    }, [savedLanguages]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const setTabError = (language) => {
        if (Array.isArray(fieldNamePrefix)) {
            return fieldNamePrefix.some((prefix) => usedFormValidationErrors?.[prefix + language]);
        } else {
            return usedFormValidationErrors?.[fieldNamePrefix + language];
        }
    };

    const renderTabs = () => {
        return languages.map(({language, label}, index) => {
            return (
                <Tab
                    key={label}
                    sx={{
                        color: setTabError(language) ? 'red!important' : 'inherit',
                    }}
                    label={label}
                    {...a11yProps(index)}
                    onClick={() => {
                        if (onlyTabs) {
                            changeLanguage({
                                path: clientCheck ? 'client' : 'admin',
                                language,
                            })
                                .unwrap()
                                .then((res) => {
                                    dispatch(setLanguage(language));
                                    document.documentElement.lang = language;
                                })
                                .catch((error) => console.log(error));
                        }
                    }}
                    tabIndex={-1}
                />
            );
        });
    };

    const setCurrentTabsContent = (language, index) => {
        if (tabContent === 'fourthStep') {
            return (
                <FourthStepContent
                    language={language}
                    receivedValue={receivedValue}
                    tabError={setTabError(language)}
                    {...rest}
                />
            );
        } else if (tabContent === 'fifthStep') {
            return (
                <FifthStepContent
                    language={language}
                    receivedValue={receivedValue}
                    tabError={setTabError(language)}
                    {...rest}
                />
            );
        } else if (tabContent === 'notificationsMultiLanguageFields') {
            return (
                <NotificationsMultiLanguageFields
                    language={language}
                    fieldName={fieldNamePrefix + language}
                    receivedValue={receivedValue}
                    {...rest}
                />
            );
        } else {
            return (
                <GeneralInput
                    fieldName={fieldNamePrefix + language}
                    label={label}
                    receivedValue={receivedValue?.[language]}
                    multiline={multiline}
                    sx={sx}
                    maxNumOfCharacters={maxNumOfCharacters}
                />
            );
        }
    };

    const renderTabsContent = () => {
        return languages.map(({language}, index) => {
            return (
                <CustomTabPanel
                    value={value}
                    index={index}
                    key={language}
                    sx={{maxWidth: 480, marginRight: 20}}
                >
                    {setCurrentTabsContent(language, index)}
                </CustomTabPanel>
            );
        });
    };

    const tabs = renderTabs();
    const tabsContent = renderTabsContent();

    function renderTabsContainer() {
        return (
            <Tabs
                className={`tabs-language ${tabsClass ? tabsClass : ''}`}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{...tabsSx}}
            >
                {tabs}
            </Tabs>
        );
    }

    const tabsContainer = renderTabsContainer();

    return (
        <>
            {!onlyTabs && (
                <Box sx={{display: 'flex'}}>
                    {tabsContent}
                    <Box sx={{borderColor: 'transparent'}}>{tabsContainer}</Box>
                </Box>
            )}
            {onlyTabs && tabsContainer}
        </>
    );
};

export default LanguageTabs;
