import {useState, useContext, useEffect} from 'react';
import {useGetNewNotificationsQuery} from '../../api/clientPartApi/apiClientPartNotificationsSlice';

import {Slider, useMediaQuery} from '@mui/material';

import HeaderAccordion from './HeaderAccordion';
import MyButton from '../../components/ui/Button/MyButton';
import HeaderNotifications from './HeaderNotifications';
import HeaderSwitchers from './HeaderSwitchers';

import {setUserButtons, setBalanceButtons} from '../../data/headerData';
import {createMuiComponent} from '../../mui/functions';

import {PageContext} from '../../pages/Page';
import {useSelector} from 'react-redux';
import {Box} from "@mui/system";


const MyHeader = createMuiComponent('header', 'MuiHeader');

const Header = () => {
    const {
        clientCheck,
        role,
        active,
        screenSize,
        dictionary,
        userStatusCounter,
        setUserStatusCounter,
        skipNotifications,
        setSkipNotifications,
        showNotifications,
        setShowNotifications
    } = useContext(PageContext);

    const WARNING_LIMIT = 90;
    const ZERO_BALANCE = 100;

    const [value, setValue] = useState(5);
    const [sliderClass, setSliderClass] = useState('');


    const {
        data: newNotifications,
        error: newNotificationsError,
        refetch: fetchNewNotifications,
    } = useGetNewNotificationsQuery(undefined, {
        skip: !clientCheck,
    });

    const newNotificationsCount = newNotifications?.notifications?.count || '';

    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
        }

        if (newValue === ZERO_BALANCE) {
            setSliderClass('warning zero-balance');
        } else if (newValue >= WARNING_LIMIT) {
            setSliderClass('warning ');
        } else {
            setSliderClass('');
        }
    };

    const valueLabelFormat = (value) => {
        return `${value}%`;
    };

    const showNotificationsHandler = () => {
        setShowNotifications(true);
        setSkipNotifications(false);
    };

    useEffect(() => {
        if (clientCheck) {
            setValue(localStorage.getItem('size_percent'));
        }
    }, [clientCheck, localStorage.getItem('size_percent')]);

    const limitStyles = (value) => ({
        backgroundColor: value >= WARNING_LIMIT && '#FD4D5C',
        borderRadius: value === ZERO_BALANCE ? 1.5 : 0,
    });

    return (
        <MyHeader
            className={`header ${active && screenSize === 'md' ? 'header--md' : ''} ${clientCheck ? 'header--client' : 'header--admin'}`}
        >
            {clientCheck && (
                <>
                    {screenSize !== 'xs' ? (<Slider
                        sx={{
                            marginLeft: screenSize === 'sm' ? '70px' : undefined,
                            pointerEvents: 'none',

                            '@media (pointer: coarse)': {
                                padding: '0 0',
                            },

                            '& .MuiSlider-track': {
                                backgroundColor: value >= WARNING_LIMIT && '#FD4D5C',
                                borderBottomRightRadius: +value === ZERO_BALANCE ? 10 : 0,
                                borderTopRightRadius: +value === ZERO_BALANCE ? 10 : 1.0,
                            },
                            '& .MuiSlider-valueLabel': {
                                backgroundColor: value >= WARNING_LIMIT && '#FD4D5C',
                            },
                        }}
                        className={sliderClass}
                        aria-label="Always visible"
                        value={value}
                        step={5}
                        onChange={handleChange}
                        valueLabelFormat={valueLabelFormat}
                        valueLabelDisplay="on"
                    />) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '5px',
                                width: '40px',
                                height: '40px',
                                backgroundColor: '#196ca7',
                                fontSize: '14px',
                                color: 'white',
                            }}
                        >{value}%</Box>
                    )}
                    {role === 'adm' && (
                        <HeaderAccordion
                            clientCheck={clientCheck}
                            title={'99 000 UAH'}
                            currentClass={
                                'header__accordion-button header__accordion-button--balance'
                            }
                            buttons={setBalanceButtons}
                        />
                    )}
                    {screenSize !== 'xs' && <HeaderSwitchers
                        userStatusCounter={userStatusCounter}
                        setUserStatusCounter={setUserStatusCounter}
                    />
                    }
                    {screenSize !== 'xs' && <MyButton
                        icon={(screenSize === 'md' || screenSize === 'sm') ? 'notificationIcon' : ''}
                        buttonClasses={'btn--small btn--notification'}
                        buttonHandler={() => showNotificationsHandler()}
                        title={ (screenSize !== 'md' && screenSize !== 'sm') && dictionary?.notifications}
                        sx={{
                            minWidth: (screenSize === 'md' || screenSize === 'sm')  ?  '42px !important'  : undefined,
                            width: (screenSize === 'md' || screenSize === 'sm')  ?  '42px !important'  : undefined,
                            minHeight: (screenSize === 'md' || screenSize === 'sm')  ?  '40px !important'  : undefined,
                            height: (screenSize === 'md' || screenSize === 'sm')  ?  '40px !important'  : undefined,
                            padding: (screenSize === 'md' || screenSize === 'sm')  ?  '0 !important'  : undefined,
                            '&::before': {
                                display: newNotificationsCount ? 'block' : 'none',
                            },
                        }}
                    />
                    }
                        <HeaderNotifications
                            showNotifications={showNotifications}
                            setShowNotifications={setShowNotifications}
                            skipNotifications={skipNotifications}
                            setSkipNotifications={setSkipNotifications}
                            fetchNewNotifications={fetchNewNotifications}
                        />
                </>
            )}
            {
                screenSize !== 'xs' && <HeaderAccordion
                    currentClass={'header__accordion-button header__accordion-button--user'}
                    buttons={setUserButtons}
                    userStatusCounter={userStatusCounter}
                    setUserStatusCounter={setUserStatusCounter}
                />
            }
        </MyHeader>
    )
        ;
};

export default Header;
