import {useContext, useEffect} from 'react';
import {Box, Typography} from '@mui/material';

import classNames from 'classnames';

import Title from '../ui/Title/Title';
import Spinner from '../ui/Spinner/Spinner';
import MyTooltip from '../ui/Tooltip/MyTooltip';

import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';

import {createMuiComponent} from '../../mui/functions';

import {PageContext} from '../../pages/Page';

const MyInformationBar = createMuiComponent('div', 'MuiInformationBar');

const InformationBar = ({
                            children,
                            title,
                            show,
                            setShow,
                            setSkip,
                            isLoading,
                            isFetching,
                            isSuccess,
                            isError,
                            sx,
                            editIcon,
                            changeContentHandler,
                        }) => {
    const {setAlertMessage, dictionary, screenSize} = useContext(PageContext);

    const hideBarHandler = () => {
        setAlertMessage({});
        setShow(false);
        setSkip(true);
    };

    const activeClass = classNames({show});


    useEffect(() => {
        if (screenSize === 'xs' && show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [screenSize, show]);

    return (
        <>
            <MyInformationBar className={'information-bar ' + activeClass} sx={{...sx}}>
                <Box className="information-bar__header">
                    <Title heading={'h2'}  title={title} sx={{lineHeight: 1, marginBottom: 0, width: screenSize === 'xs' ? '200px' : undefined }}/>
                    {editIcon && (
                        <MyTooltip position={'right'} title={dictionary?.edit}>
                            <EditIcon
                                className="information-bar__icon information-bar__icon--edit"
                                onClick={() =>
                                    changeContentHandler('editVisitor', dictionary?.edit_visitor)
                                }
                            />
                        </MyTooltip>
                    )}
                    <CloseIcon
                        className="information-bar__icon information-bar__icon--close"
                        onClick={() => hideBarHandler()}
                    />
                </Box>
                {isError && <Typography variant="h3">ERROR</Typography>}
                {(isLoading || isFetching) && <Spinner sx={{borderRadius: 0}}/>}
                {isSuccess && children}
            </MyInformationBar>
        </>
    );
};

export default InformationBar;
