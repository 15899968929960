import {Button} from '@mui/material';
import {ReactComponent as NotificationIcon} from '../../../assets/icons/notification-icon-white.svg';

const MyButton = ({
                      icon,
                      title,
                      buttonHandler,
                      buttonClasses,
                      disabled = false,
                      sx,
                  }) => {
    /*  console.log('====================================');
      console.log(disabled);
      console.log('====================================');*/
    return (
        <Button
            className={`btn ${buttonClasses}`}
            sx={sx ? {...sx} : null}
            variant="contained"
            onClick={buttonHandler}
            disabled={disabled}
        >
            {icon === 'notificationIcon' && <NotificationIcon />}
            {title}
        </Button>
    );
};

export default MyButton;
