import {useState, useContext, useLayoutEffect} from 'react';

import {NavLink} from 'react-router-dom';

import Counter from '../../components/ui/Counter/Counter';

import {PageContext} from '../../pages/Page';

import {setNavLinkName} from '../../data/navLinkData';

import {useSocketListener} from '../../hooks/useSocketListener';

const NavSubItem = ({route, isSuccess, value, setInitialProps}) => {
    const {setAlertMessage, dictionary, screenSize, active, setActive,} = useContext(PageContext);

    const [currentValue, setCurrentValue] = useState('');

    const {
        path,
        // linkName,
        counter,
        counterType,
        initialContent,
    } = route;

    const linkName = setNavLinkName(dictionary).filter(
        ({id, hiddenRoutes}) => path === id && hiddenRoutes
    )[0]?.linkName;

    useLayoutEffect(() => {
        if (value) {
            if (typeof value === 'object') {
                for (const [key, currentValue] of Object.entries(value)) {
                    if (key === counterType) {
                        setCurrentValue(currentValue);
                    }
                }
            } else {
                setCurrentValue(value);
            }
        }
    }, [value]);

    useSocketListener('support.message.send', (data) => {
        if (data) {
            if (typeof data === 'object') {
                for (const [key, currentValue] of Object.entries(data)) {
                    if (key === counterType) {
                        setCurrentValue(currentValue);
                    }
                }
            } else {
                setCurrentValue(data);
            }
        }
    });

    return (
        <li className="nav__sub-item">
            <NavLink
                onClick={() => {
                    setAlertMessage({});
                    if (initialContent) {
                        setInitialProps(initialContent);
                    }
                  if (screenSize === 'xs' && active) {
                    setActive(!active);
                  }
                }}
                className={({isActive}) =>
                    isActive
                        ? 'nav__link nav__link--secondary _active'
                        : 'nav__link nav__link--secondary'
                }
                to={path}
            >
                {linkName}
                {counter && isSuccess && currentValue && (
                    <Counter
                        sx={{position: 'absolute', right: 14, top: 9}}
                        indicator={false}
                    >
                        {currentValue}
                    </Counter>
                )}
            </NavLink>
        </li>
    );
};

export default NavSubItem;
