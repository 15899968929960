import {useEffect, useContext} from 'react';

import {Box} from '@mui/material';

import MyButton from '../../../../components/ui/Button/MyButton';
import GeneralInput from '../../../../components/Form/FormInputs/GeneralInput';
import SelectWithFormContext from '../../../../components/Form/FormSelect/Select/SelectWithFormContext';
import StepCode from './StepCode';
import {FlexBox, theme} from '../../../../mui/theme';

import {PageContext} from '../../../Page';
import {secondary} from "../../../../helpers/variables";

const buttonsTitles = [{title: 'UA', serverTitle: 'uk'}, {title: 'EN'}, {title: 'RU'}];

const FirstStep = ({
                       selectedLanguages,
                       setSelectedLanguages,
                       checkDomainHandler,
                       isSuccess,
                       domain,
                       setDomain,
                       confirmButtonHandler,
                       activeLanguageButtons,
                       setActiveLanguageButtons,
                       savedSite,
                       copiedText,
                       copy,
                       verificationCode,
                       site,
                       content,
                       resetDomainHandler,
                       resetSiteDomain,
                       setDefaultLanguage,
                   }) => {
    const {dictionary} = useContext(PageContext);
    useEffect(() => {
        if (savedSite?.languages || (site?.languages && content === 'editSite')) {
            let currentSite = [];
            let savedActiveLanguages = {};
            let arrOfLanguages = [];

            if (!savedSite?.languages) {
                currentSite = site.languages;
            } else {
                currentSite = savedSite.languages;
            }

            currentSite.forEach((language) => {
                savedActiveLanguages[language] = 'active';
                arrOfLanguages.push(language);
            });

            setSelectedLanguages(arrOfLanguages);

            setActiveLanguageButtons({...activeLanguageButtons, ...savedActiveLanguages});
        }
    }, []);

    const selectedLanguagesHandler = (language) => {
        if (activeLanguageButtons[language]) {
            setActiveLanguageButtons({...activeLanguageButtons, [language]: ''});
            let filteredLanguages = selectedLanguages.filter((lang) => lang !== language);

            setSelectedLanguages([...filteredLanguages]);
        } else {
            setSelectedLanguages([...selectedLanguages, language]);
            setActiveLanguageButtons({...activeLanguageButtons, [language]: 'active'});
        }
    };

    const textareaValue =
        verificationCode && ((content === 'createSite' && !savedSite?.id) || resetSiteDomain)
            ? `<!DOCTYPE html>
<html>
  <head>
    <meta name='streamchat-verification-code' content='${verificationCode || 'code'}' /> 
    <title>Hello World</title>
  </head>
  <body>
    <h1>Hello world!</h1>
  </body>
</html>`
            : '';

    return (
        <>
            <Box sx={{
                [theme.breakpoints.down(550)]: {display: 'flex'}
            }}>
                {buttonsTitles.map(({title, serverTitle}) => {
                    return (
                        <MyButton
                            key={title}
                            buttonClasses={
                                'btn--transparent btn--transparent-small ' +
                                activeLanguageButtons[serverTitle || title.toLowerCase()]
                            }
                            sx={{
                                [theme.breakpoints.down(550)]: {
                                    flex: 1,
                                    border: 'none !important',
                                    borderRadius: '0 !important',
                                    marginRight: '0 !important',
                                    borderBottom: '2px solid var(--basic-color-300) !important',
                                    backgroundColor: 'none',
                                    maxWidth: '100% !important',
                                    '&.btn--transparent-small.active': {
                                        color: secondary.main,
                                        borderColor: secondary.main + ' !important',
                                        backgroundColor: 'transparent !important',
                                    },
                                },
                            }}
                            title={title}
                            buttonHandler={() => {
                                selectedLanguagesHandler(serverTitle || title.toLowerCase());
                            }}
                        />
                    );
                })}
            </Box>
            <SelectWithFormContext
                fieldName={'default_language'}
                label={dictionary?.default_language}
                labelId={'languageSelect'}
                options={selectedLanguages.map((lang) => {
                    switch (lang) {
                        case 'uk':
                            return {id: lang, name: dictionary?.ukrainian};
                        case 'en':
                            return {id: lang, name: dictionary?.english};
                        case 'ru':
                            return {id: lang, name: dictionary?.russian};
                    }
                })}
                receivedValue={
                    selectedLanguages.length !== 0
                        ? selectedLanguages[0]
                        : savedSite?.default_language
                }
                disabled={selectedLanguages.length === 0}
                setSelectedValue={setDefaultLanguage}
                useSelectedValueImmediatly={true}
            />
            <FlexBox className="row-18">
                <GeneralInput
                    fieldName={'domain'}
                    label={dictionary?.domain}
                    placeholder={'site.com'}
                    getCurrentValue={true}
                    setCurrentValue={setDomain}
                    receivedValue={savedSite?.domain?.replace('https://', '')}
                    disabled={
                        (!resetSiteDomain && content === 'editSite') ||
                        (!resetSiteDomain && content === 'createSite' && !!savedSite?.id)
                    }
                    sx={{
                        '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
                            WebkitTextFillColor: '#363f48',
                        },
                    }}
                />
                <MyButton
                    buttonClasses={'btn--extra-small'}
                    title={
                        (!resetSiteDomain && content === 'editSite') ||
                        (!resetSiteDomain && content === 'createSite' && savedSite?.id)
                            ? dictionary?.change
                            : dictionary?.check
                    }
                    buttonHandler={() => {
                        if (
                            (!resetSiteDomain && content === 'editSite') ||
                            (!resetSiteDomain && content === 'createSite' && savedSite?.id)
                        ) {
                            resetDomainHandler(savedSite?.id);
                        } else {
                            checkDomainHandler();
                        }
                    }}
                    disabled={domain?.domain || savedSite?.domain ? false : true}
                />
            </FlexBox>
            <StepCode
                isSuccess={isSuccess}
                informationText={`${dictionary?.insert_text} <head> ${verificationCode}`}
                informationTextWrapperSx={{alignItems: 'center'}}
                verificationCode={verificationCode}
                textareaValue={textareaValue}
                textareaLabel={dictionary?.verification_code}
                textareaHeight={{height: '220px!important'}}
                fieldName={'verification_code'}
                successText={dictionary?.domain_success}
                confirmButtonHandler={confirmButtonHandler}
                copiedText={copiedText}
                copy={copy}
                buttonTitle={dictionary?.confirm}
                content={content}
                resetSiteDomain={resetSiteDomain}
                savedSite={savedSite}
            />
        </>
    );
};

export default FirstStep;
