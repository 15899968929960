import {useContext, useLayoutEffect} from 'react';
import {useDispatch} from 'react-redux';

import {apiSlice} from '../../api/apiSlice';

import {TablePagination, Pagination, PaginationItem} from '@mui/material';

import {ReactComponent as ArrowForwardIcon} from '../../assets/icons/arrow-left.svg';
import {ReactComponent as ArrowBackwardIcon} from '../../assets/icons/arrow-right.svg';
import {ReactComponent as doubleArrowForwardIcon} from '../../assets/icons/double-arrow-forward.svg';
import {ReactComponent as doubleArrowBackwardIcon} from '../../assets/icons/double-arrow-backward.svg';

import {PageContainerContext} from '../../pages/PageContainer';
import {PageContext} from "../../pages/Page";

const TblPagination = ({
                           data,
                           withoutQueryParams,
                           setPageNumber,
                           invalidateTag,
                           parametr = 'page',
                           ...rest
                       }) => {
    const {screenSize} = useContext(PageContext);
    const {setQueryParams} = useContext(PageContainerContext);
    const pages = withoutQueryParams ? [3, 12, 21] : [5, 15, 25, 50];
    const INITIAL_VALUE = 0;

    const dispatch = useDispatch();

    // when removed last element on the page
    // or if pagination not up to date
    useLayoutEffect(() => {
        if (data?.last_page < data?.current_page) {
            setQueryParams((params) => {
                params.set(parametr, data?.last_page);

                return params;
            });

            if (invalidateTag) {
                dispatch(apiSlice.util.invalidateTags([invalidateTag]));
            }
        }
    }, [data]);

    const TablePaginationActions = () => {
        const func = (event, number) => {
            if (withoutQueryParams) {
                setPageNumber(number);
            } else {
                setQueryParams((params) => {
                    params.set(parametr, number);

                    return params;
                });
            }
        };

        return (
            <Pagination
                count={data?.last_page || INITIAL_VALUE}
                page={data?.current_page || INITIAL_VALUE}
                showFirstButton
                showLastButton
                siblingCount={1}
                boundaryCount={screenSize === 'xs' && 0}
                onChange={(event, item) => func(event.target, item)}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{
                            prev: ArrowForwardIcon,
                            next: ArrowBackwardIcon,
                            first: doubleArrowForwardIcon,
                            last: doubleArrowBackwardIcon,
                        }}
                        {...item}
                    />
                )}
                hidePrevButton={screenSize === 'xs'}
                hideNextButton={screenSize === 'xs'}
                {...rest}
            />
        );
    };

    if (data?.total <= data?.per_page || data.length === 0) {
        return null;
    } else
        return (
            <TablePagination
                sx={{
                    '& .MuiToolbar-root': {
                        display: 'block',
                        minHeight: 40,
                        marginBottom: 0,
                    },
                    '& .MuiInputBase-root': {
                        display: 'none',
                    },
                }}
                component="div"
                labelRowsPerPage=""
                page={data?.current_page - 1 || INITIAL_VALUE}
                rowsPerPageOptions={pages}
                rowsPerPage={Number(data?.per_page) || pages[0]}
                count={data?.total || INITIAL_VALUE}
                onPageChange={() => ''}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={() => ''}
            />
        );
};

export default TblPagination;
