import { useContext, useLayoutEffect } from 'react';
import {
    useGetCommandsQuery,
    useGetCommandQuery,
} from '../../../api/clientPartApi/apiTemplatesSlice';

import Section from '../../../layouts/Section/Section';
import CommandsList from './CommandsList';
import CommandsForm from './CommandsForm';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const Commands = () => {
    const { dictionary } = useContext(PageContext);
    const { createSlug, content, changeContentHandler, skip, selectedElementId, hash } =
        useContext(PageContainerContext);

    useLayoutEffect(() => {
        if (hash === '#create' && content !== 'createCommand') {
            changeContentHandler('createCommand', '', '', 'templates');
        } else if (hash.includes('#command') && content !== 'editCommand') {
            const id = hash.slice(hash.indexOf('_') + 1);
            changeContentHandler('editCommand', id, true, 'templates');
        } else if (!hash && content !== 'commandsList') {
            changeContentHandler('commandsList', '', '', 'templates');
        }
    }, [hash]);

    const {
        data: commands,
        isLoading: commandsLoading,
        isFetching: commandsFetching,
        isSuccess: commandsSuccess,
        isError: commandsFetchingError,
        error: commandsError,
    } = useGetCommandsQuery(
        {
            limit: createSlug('limit'),
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
            search: createSlug('q'),
            categories: createSlug('categories', 'array'),
        },
        { skip }
    );

    const {
        data: selectedCommand,
        isLoading: selectedCommandLoading,
        isFetching: selectedCommandFetching,
        isSuccess: selectedCommandSuccess,
        isError: selectedCommandFetchingError,
    } = useGetCommandQuery(content === 'editCommand' ? selectedElementId : '', {
        skip: content !== 'editCommand',
    });

    const commandsList = commands?.data?.commands?.data || [];
    const command = selectedCommand?.data || '';
    const commandsListPaginationData = commands?.data?.commands || {};

    return (
        <Section
            key={selectedCommand?.data?.id}
            isLoading={commandsLoading || selectedCommandLoading}
            isFetching={commandsFetching || selectedCommandFetching}
            isError={commandsFetchingError || selectedCommandFetchingError}
            error={commandsError}
            title={
                content === 'commandsList'
                    ? dictionary?.commands_list
                    : content === 'createCommand'
                    ? dictionary?.create_command
                    : dictionary?.edit_command
            }
            sx={{ maxWidth: content === 'commandsList' ? 1920 : 540 }}
            content={content}
        >
            {(!content || content === 'commandsList') && (
                <CommandsList
                    commandsList={commandsList}
                    commandsSuccess={commandsSuccess}
                    changeContentHandler={changeContentHandler}
                    paginationData={commandsListPaginationData}
                />
            )}
            {(content === 'createCommand' || content === 'editCommand') && (
                <CommandsForm
                    content={content}
                    command={content === 'editCommand' ? command : ''}
                    selectedCommandSuccess={selectedCommandSuccess}
                />
            )}
        </Section>
    );
};

export default Commands;
