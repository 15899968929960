import {useContext, useEffect, useState, useLayoutEffect} from 'react';

import {TextField, InputAdornment} from '@mui/material';

import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';

import {PageContainerContext} from '../../pages/PageContainer';
import {PageContext} from "../../pages/Page";

const SearchBar = ({
                       placeholder,
                       withoutSearchQueryString,
                       setSearch,
                       disabled,
                       content,
                   }) => {
    const {queryParams, setQueryParams} = useContext(PageContainerContext);
    const {screenSize} = useContext(PageContext);

    const [searchQuery, setSearchQuery] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        if (isTyping) {
            if (searchQuery) {
                const timeoutId = setTimeout(() => {
                    if (withoutSearchQueryString) {
                        setSearch(searchQuery);
                    } else {
                        setQueryParams(
                            (params) => {
                                setIsTyping(false);
                                params.set('q', encodeURIComponent(searchQuery));
                                params.delete('page');
                                return params;
                            }
                            // { replace: true }
                        );
                    }
                }, 300);

                return () => clearTimeout(timeoutId);
            } else {
                if (withoutSearchQueryString) {
                    setSearch('');
                } else {
                    setQueryParams(
                        (params) => {
                            setIsTyping(false);
                            params.delete('q');

                            return params;
                        }
                        // { replace: true }
                    );
                }
            }
        }
    }, [searchQuery, queryParams]);

    // when refresh the page or click on the back/forward browser button
    useLayoutEffect(() => {
        if (!isTyping) {
            if (
                queryParams.get('q') &&
                (!searchQuery ||
                    searchQuery !== decodeURIComponent(queryParams.get('q')))
            ) {
                setSearchQuery(decodeURIComponent(queryParams.get('q')));
            } else if (!queryParams.get('q')) {
                setSearchQuery('');
            }
        }
    }, [queryParams]);
    const handleSearch = ({target}) => {
        setSearchQuery(target.value);
        setIsTyping(true);
    };
    return (
        <TextField
            className="search"
            variant="outlined"
            value={searchQuery}
            placeholder={placeholder}
            sx={{
              maxWidth: (
                  content === 'employeesList' ||
                  content === 'dialoguesList' ||
                  content === 'dialoguesList' ||
                  (content === 'commandsList' && screenSize === 'xs')
              ) ? '100%' : 'fit-content', width: '100%'}}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon/>
                    </InputAdornment>
                ),
            }}
            disabled={disabled}
            onChange={handleSearch}
        />
    );
};

export default SearchBar;
