import React, { useContext } from 'react';
import { useDeleteCommandMutation } from '../../../api/clientPartApi/apiTemplatesSlice';

import {
    TableBody,
    TableRow,
    TableCell,
    Box,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails
} from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';

import { setCommandsTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {setStatusLabel} from "../../../helpers/functions";


const boxStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'column',
}));


const boxIconStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '24px',
}));


const typographyStyled = (({}) => ({
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    maxWidth: '280px',
}));


const CommandsList = ({ commandsList, commandsSuccess, paginationData, changeContentHandler }) => {
    const { navigate, dictionary, screenSize } = useContext(PageContext);

    const [deleteCommand] = useDeleteCommandMutation();

    const deleteCommandHandler = (id) => {
        deleteCommand(id).unwrap();
    };

    return (
        <>
            <MyToolbar
                filter={true}
                moveToLeft={true}
                search={dictionary?.command_search_placeholder}
                buttonSx={{ position: 'absolute', left: 0, top: 0, zIndex: 1 }}
                buttonClass={'btn--small'}
                paginationData={screenSize !== 'xs' && paginationData}
                buttonHandler={() => {
                    changeContentHandler('createCommand', '', '', 'templates');
                    navigate('/templates/commands#create');
                }}
            />
            {screenSize === 'xs' && <Box>

                {commandsList.length === 0 && commandsSuccess && <EmptyListAlertText />}
                {commandsList.length > 0 &&
                    commandsList.map(({ id, trigger, text, categories }) => {
                        return  (
                                <Accordion
                                    sx={{
                                        position: 'inherit',
                                    }}
                                    className='accordion__item'
                                >
                                    <AccordionSummary
                                        sx={{
                                            minHeight: '50px',
                                            fontSize: 'var(--fz-400)',
                                            padding: '13px 16px',
                                            lineHeight: 1.5,
                                            borderRadius: 0,
                                            display: 'flex',
                                            width: '100%',
                                            fontWeight: '600 !important',
                                            textAlign: 'left',
                                            border: 'none',
                                            color: 'var(--basic-color)',
                                            transition: 'background-color 50ms',
                                        }}
                                        className='accordion__header'
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            sx={{
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                maxWidth: '50vw',
                                            }}>
                                            {`* ${trigger}`}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingX: '16px !important',
                                            gap: '12px'
                                        }}
                                    >
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">{dictionary.trigger}</Typography>
                                            <Typography
                                                sx={typographyStyled}>
                                                {`* ${trigger}`}
                                            </Typography>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">{dictionary.content}</Typography>
                                            <Typography
                                                sx={typographyStyled}>
                                                <Box
                                                    sx={{ '& p': { margin: 0 }, wordBreak: 'break-all' }}
                                                    dangerouslySetInnerHTML={{ __html: text }}
                                                />
                                            </Typography>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">{dictionary.category}</Typography>
                                            <Typography
                                                sx={typographyStyled}>
                                                {categories.map((categorie, index) => {
                                                    if (
                                                        (categories.length === index + 1 &&
                                                            categories.length > 1) ||
                                                        categories.length === 1
                                                    ) {
                                                        return categorie.name;
                                                    } else {
                                                        return categorie.name + ', ';
                                                    }
                                                })}
                                            </Typography>
                                        </Box>
                                        <Box sx={boxIconStyled}>
                                            <MyTooltip position={'top'} title={dictionary?.edit}>
                                                <EditIcon
                                                    onClick={() => {
                                                        changeContentHandler(
                                                            'editCommand',
                                                            id,
                                                            true,
                                                            'templates'
                                                        );
                                                        navigate(`/templates/commands#command_${id}`);
                                                    }}
                                                />
                                            </MyTooltip>
                                            <MyTooltip position={'top'} title={dictionary?.delete}>
                                                <DeleteIcon onClick={() => deleteCommandHandler(id)} />
                                            </MyTooltip>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }
                    )}
            </Box>}
            { screenSize !== 'xs' && <TblContainer>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {commandsList.length === 0 && commandsSuccess && <EmptyListAlertText />}
                    {commandsList.length > 0 &&
                        commandsList.map(({ id, trigger, text, categories }) => {
                            return (
                                <TableRow className="table-main__row" key={id}>
                                    <TableCell>{`* ${trigger}`}</TableCell>
                                    <TableCell>
                                        <Box
                                            sx={{ '& p': { margin: 0 }, wordBreak: 'break-all' }}
                                            dangerouslySetInnerHTML={{ __html: text }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {categories.map((categorie, index) => {
                                            if (
                                                (categories.length === index + 1 &&
                                                    categories.length > 1) ||
                                                categories.length === 1
                                            ) {
                                                return categorie.name;
                                            } else {
                                                return categorie.name + ', ';
                                            }
                                        })}
                                    </TableCell>
                                    <TableCell className="action">
                                        <MyTooltip position={'top'} title={dictionary?.edit}>
                                            <EditIcon
                                                onClick={() => {
                                                    changeContentHandler(
                                                        'editCommand',
                                                        id,
                                                        true,
                                                        'templates'
                                                    );
                                                    navigate(`/templates/commands#command_${id}`);
                                                }}
                                            />
                                        </MyTooltip>
                                        <MyTooltip position={'top'} title={dictionary?.delete}>
                                            <DeleteIcon onClick={() => deleteCommandHandler(id)} />
                                        </MyTooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>}
            <TblPagination data={paginationData} invalidateTag={'Commands'} />
        </>
    );
};

export default CommandsList;
