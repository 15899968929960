import { useContext } from 'react';

import { Grid } from '@mui/material';

import FilterFormContainer from '../../../components/Filter/FilterFormContainer';
import MyDatePicker from '../../../components/DatePicker/DatePicker';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

import { setFilterParams } from '../../../helpers/functions';

const currentQueryParams = ['range_start', 'range_end'];

const PaymentFilter = ({ clearFilter }) => {
    const { dictionary } = useContext(PageContext);
    const { queryParams, setQueryParams } = useContext(PageContainerContext);

    const formSubmitHandler = (data) => {
        setQueryParams(
            (params) => {
                if (queryParams.get('page')) {
                    params.delete('page');
                }

                setFilterParams(data.date && data.date[0] ? data.date[0].format('YYYY/MM/DD HH:mm') : '', 'range_start', params);
                setFilterParams(data.date && data.date[1] ? data.date[1].format('YYYY/MM/DD HH:mm') : data.date && data.date[0] ? data.date[0].format('YYYY/MM/DD HH:mm') : '', 'range_end', params);
                return params;
            },
            { replace: true }
        );
    };

    return (
        <FilterFormContainer
            formSubmitHandler={formSubmitHandler}
            currentQueryParams={currentQueryParams}
        >
            <Grid item xs={4.4654}>
                <MyDatePicker
                    fieldName={'date'}
                    clearFilter={clearFilter}
                    placeholder={dictionary?.date}
                    withoutTime={true}
                    receivedValue={
                        queryParams.get('range_start')
                            ? [queryParams.get('range_start'), queryParams.get('range_end')]
                            : ''
                    }
                />
            </Grid>
        </FilterFormContainer>
    );
};

export default PaymentFilter;
