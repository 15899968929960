import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {
    useGetDialoguesListQuery,
    useConnectToDialogueMutation,
} from '../../../api/clientPartApi/apiDialoguesSlice';

import {
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';

import Section from '../../../layouts/Section/Section';
import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyLink from '../../../components/ui/Link/MyLink';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import {ReactComponent as ViewingIcon} from '../../../assets/icons/hide.svg';
import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg';

import {
    setDialoguesTableHeadings as setHeadCells,
    setOperatorDialoguesTableHeadings as setOperatorHeadCells,
} from '../../../data/tableHeadingsData';

import {dateConverter, setStatusLabel} from '../../../helpers/functions';

import {PageContext} from '../../Page';
import {PageContainerContext} from '../../PageContainer';

import {socket} from '../../Page';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const boxStyled = (({}) => ({
  display: 'flex',
  flexDirection: 'column',
}));


const boxIconStyled = (({}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '24px',
}));

const AllDialogues = () => {
    const {role, navigate, dictionary, clientId, screenSize, active} = useContext(PageContext);
    const {
        createSlug,
        content,
        skip,
        hash,
        changeContentHandler,
        setSelectedDialogueWithRedirection,
    } = useContext(PageContainerContext);

    useLayoutEffect(() => {
        if (!hash && content !== 'dialoguesList') {
            changeContentHandler('dialoguesList', '', '', 'dialogues');
        }
    }, [hash]);

    const {
        data: dialogues,
        isFetching: dialoguesFetching,
        isLoading: dialoguesLoading,
        isSuccess: dialoguesSuccess,
        isError: dialoguesFetchingError,
        error: dialoguesError,
        refetch: fetchDialogues,
    } = useGetDialoguesListQuery(
        {
            limit: createSlug('limit'),
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
            search: createSlug('q'),
            sites: createSlug('sites', 'array'),
            user: createSlug('user'),
            visitor: createSlug('visitor'),
            tag: createSlug('tag'),
            country: createSlug('country'),
            status: createSlug('status'),
            range_start: createSlug('range_start'),
            created_at: createSlug('created_at'),
            range_end: createSlug('range_end'),
        },
        {skip}
    );

    const [connectToDialog] = useConnectToDialogueMutation();

    const dialoguesList = dialogues ? dialogues?.data?.dialogs?.data : [];
    const dialoguesListPaginationData = dialogues?.data?.dialogs || {};

    const viewDialogHandler = (id, status) => {
        const url = status === 'waiting'
            ? `/dialogues/waiting#dialogue_${id}`
            : `/dialogues/active#dialogue_${id}`;

        window.open(url, '_blank'); // Открывает в новой вкладке
    };
    useEffect(() => {
        const updateDialoguesList = (data) => {
            fetchDialogues();
        };

        socket.on('dialog.list', updateDialoguesList);
        socket.on('visitor.list', updateDialoguesList);

        return () => {
            socket.off('dialog.list', updateDialoguesList);
            socket.off('visitor.list', updateDialoguesList);
        };
    }, []);

    const connectToDialogHandler = (id, status) => {
        connectToDialog(id)
            .unwrap()
            .then((res) => {
                setSelectedDialogueWithRedirection({
                    isClicked: true,
                    status: status === 'waiting' ? 'waiting' : 'someonesUnresolved',
                    redirectedFromActiveOrWaitingDialoguesSection: false,
                });
                navigate(`/dialogues/active#dialogue_${id}`);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <Section
            isLoading={dialoguesLoading}
            isFetching={dialoguesFetching}
            isError={dialoguesFetchingError}
            error={dialoguesError}
            title={dictionary?.dialogues_list}
            content={content}
        >
            <MyToolbar
                filter={true}
                search={dictionary?.dialogues_search_placeholder}
                paginationData={screenSize !== 'xs' && dialoguesListPaginationData}
                changeContentButton={false}
            />
            {screenSize === 'xs' && <Box>
                {dialoguesList.length === 0 && dialoguesSuccess && (
                    <EmptyListAlertText/>
                )}
                {dialoguesList.length > 0 &&
                    dialoguesList.map(
                        ({
                             id,
                             visitor,
                             site,
                             user,
                             users,
                             created_at,
                             last_message_at,
                             status,
                         }) => {
                            return(
                                <Accordion
                                    sx={{
                                        position: 'inherit',
                                    }}
                                    className='accordion__item'
                                >
                                    <AccordionSummary
                                        sx={{
                                            minHeight: '50px',
                                            fontSize: 'var(--fz-400)',
                                            padding: '13px 16px',
                                            lineHeight: 1.5,
                                            borderRadius: 0,
                                            display: 'flex',
                                            width: '100%',
                                            fontWeight: '600 !important',
                                            textAlign: 'left',
                                            border: 'none',
                                            color: 'var(--basic-color)',
                                            transition: 'background-color 50ms',
                                        }}
                                        className='accordion__header'
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            sx={{
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                maxWidth: '50vw',
                                            }}>
                                            {visitor?.fullname}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingX: '16px !important',
                                            gap: '12px'
                                        }}
                                    >
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">{dictionary.dialogue_id}</Typography>
                                            <Typography
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    maxWidth: '280px',
                                                }}>
                                                {id}
                                            </Typography>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">{dictionary.visitor}</Typography>

                                            <Typography
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    maxWidth: '280px',
                                                }}>
                                                {visitor?.fullname}
                                            </Typography>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">{dictionary.user}</Typography>

                                            <Typography
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    maxWidth: '280px',
                                                }}>
                                                {user?.name}
                                            </Typography>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">{dictionary.status}</Typography>
                                            <Typography sx={{color: status === 'active' ? 'var(--color-accent-first)' : 'var(--color-accent-second)'}}>
                                                {setStatusLabel(status, dictionary)}
                                            </Typography>
                                        </Box>
                                        <Box sx={boxIconStyled}>
                                            <MyTooltip position={'top'} title={dictionary?.viewing}>
                                                <ViewingIcon
                                                    onClick={() => {
                                                        viewDialogHandler(id, status);
                                                    }}
                                                />
                                            </MyTooltip>
                                            {(status === 'waiting' ||
                                                (status === 'unresolved' &&
                                                    !users.includes(clientId))) && (
                                                <MyTooltip position={'top'} title={dictionary?.connect}>
                                                    <AddIcon
                                                        onClick={() => connectToDialogHandler(id, status)}
                                                    />
                                                </MyTooltip>
                                            )}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }
                    )}
            </Box>}
            {screenSize !== 'xs' && <TblContainer tableSx={{
                overflowX: screenSize === 'sm' || (screenSize === 'md') ? 'overlay' : undefined,
                overflowY: 'hidden',
            }}>
                <TblHead
                    setHeadCells={role !== 'op' ? setHeadCells : setOperatorHeadCells}
                />
                <TableBody>
                    {dialoguesList.length === 0 && dialoguesSuccess && (
                        <EmptyListAlertText/>
                    )}
                    {dialoguesList.length > 0 &&
                        dialoguesList.map(
                            ({
                                 id,
                                 visitor,
                                 site,
                                 user,
                                 users,
                                 created_at,
                                 last_message_at,
                                 status,
                             }) => {
                                return (
                                    <TableRow className="table-main__row" key={id}>
                                        <TableCell className="ellipsis ellipsis--allDialogues">
                                            <MyTooltip
                                                position={'top-start'}
                                                title={id}
                                                arrow={false}
                                                interactive={false}
                                            >
                                                <Typography>{id}</Typography>
                                            </MyTooltip>
                                        </TableCell>
                                        <TableCell className="ellipsis">
                                            <MyTooltip
                                                position={'top-start'}
                                                title={visitor?.fullname}
                                                arrow={false}
                                                interactive={false}
                                            >
                                                <Typography>{visitor?.fullname}</Typography>
                                            </MyTooltip>
                                        </TableCell>
                                        <TableCell>
                                            <MyLink
                                                link={`${site?.protocol}://${site?.domain}`}
                                                text={site?.domain}
                                            />
                                        </TableCell>
                                        {role !== 'op' && <TableCell>{user?.name}</TableCell>}
                                        <TableCell className="status">
                                            <Typography className={status}>
                                                {setStatusLabel(status, dictionary)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{dateConverter(created_at)}</TableCell>
                                        <TableCell>{dateConverter(last_message_at)}</TableCell>
                                        <TableCell className="action">
                                            <MyTooltip position={'top'} title={dictionary?.viewing}>
                                                <ViewingIcon
                                                    onClick={() => {
                                                        viewDialogHandler(id, status);
                                                    }}
                                                />
                                            </MyTooltip>
                                            {(status === 'waiting' ||
                                                (status === 'unresolved' &&
                                                    !users.includes(clientId))) && (
                                                <MyTooltip position={'top'} title={dictionary?.connect}>
                                                    <AddIcon
                                                        onClick={() => connectToDialogHandler(id, status)}
                                                    />
                                                </MyTooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                </TableBody>
            </TblContainer>}
            <TblPagination
                data={dialoguesListPaginationData}
                invalidateTag={'Dialogues'}
            />
        </Section>
    );
};

export default AllDialogues;
