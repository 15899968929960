import {useState, useContext} from 'react';
import {useLogoutMutation} from '../../api/clientPartApi/apiLogoutSlice';
import {useDispatch} from 'react-redux';
import {setSkip} from '../../reducers/sectionSlice';
import {NavLink} from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionSummary, AccordionDetails, Button, ButtonGroup} from '@mui/material';
import {ClickAwayListener} from '@mui/base/ClickAwayListener';

import {PageContext} from '../../pages/Page';
import {PageContainerContext} from '../../pages/PageContainer';
import {ReactComponent as UserIcon} from '../../assets/icons/user.svg';

import {saveToLocalStorage, clearLocalStorageArray} from '../../helpers/functions';
import {theme} from "../../mui/theme";

const HeaderAccordion = ({
                             currentClass,
                             buttons,
                             title,
                             userStatusCounter,
                             setUserStatusCounter,
                             sidebarMenu = false,
                         }) => {
    const {role, login, indexPath, clientCheck, clearUserData, navigate, dictionary, active, setActive, screenSize} =
        useContext(PageContext);

    const dispatch = useDispatch();

    const {changeContentHandler} = useContext(PageContainerContext);

    const [open, setOpen] = useState(false);

    const [logout] = useLogoutMutation();

    const buttonGroupStyles = {
        width: '100%',
        '.MuiButton-text': {
            paddingBlock: 1,

            fontSize: 16,
            lineHeight: 1.5,
            borderRadius: '5px!important',
        },
        '.MuiButton-text:hover': {
            backgroundColor: 'basicColor.color200',
        },
    };

    const clickAwayHandler = () => {
        setOpen(false);
    };

    const setPageContent = (id) => {
        setOpen(false);
        changeContentHandler(id);

        if (id === 'exit') {
            navigate(`/${indexPath}`);
        }
    };

    const exitButtonHandler = () => {
        if (role === 'op') {
            logout().unwrap();
        }


        setUserStatusCounter(clearTimeout(userStatusCounter));
        clearUserData();
        clearLocalStorageArray("sites");

        const filterUserData = (data, user) => {
            const userData = Object.entries(JSON.parse(data));

            const filtered = userData.filter(([key, value]) => key === 'language');

            const justLanguage = Object.fromEntries(filtered);

            saveToLocalStorage(user, justLanguage);
        };

        if (clientCheck) {
            filterUserData(localStorage.getItem('client'), 'client');
        } else {
            filterUserData(localStorage.getItem('admin'), 'admin');
        }
    };

    const buttonHandler = (id) => {
        if (id === 'exit') {
            exitButtonHandler();
        } else {
            setPageContent(id);
            dispatch(setSkip(false));
        }
    };

    const renderButtonList = () => {
        const button = (id, styles, title) => {
            return (
                <Button
                    key={id}
                    onClick={() => {
                        buttonHandler(id);
                        if (active) {
                            setActive(false)
                    }}}
                    variant="text"
                    sx={{...styles}}
                    disableRipple
                >
                    {title}
                </Button>
            );
        };
        return buttons(dictionary).map(({id, styles, title}) => {
            if (id !== 'exit') {
                return (
                    <NavLink to={id} key={id}>
                        {button(id, styles, title)}
                    </NavLink>
                );
            } else {
                return button(id, styles, title);
            }
        });
    };

    const buttonList = renderButtonList();

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (open) {
                    clickAwayHandler();
                }
            }}
        >
            <Accordion className="header__accordion" expanded={open}
                       sx={{
                           [theme.breakpoints.down(550)]: {
                               zIndex:sidebarMenu && '1 !important',
                           },
                       }}>
                <AccordionSummary
                    sx={{
                        height: (screenSize === 'md' || screenSize === 'sm')  ? '40px !important' : undefined,
                        [theme.breakpoints.down(550)]: {
                            padding: sidebarMenu ? '13px 16px' : '0px 10px',
                            minHeight: sidebarMenu ? '70px' : '40px !important',
                            borderBottom: sidebarMenu && '2px solid var(--basic-color-100)',
                            backgroundColor: sidebarMenu && 'basicColor.colorWhite',
                        },
                    }}
                    onClick={() => {
                        setOpen(!open);
                    }}
                    className={currentClass}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                >
                    {currentClass.includes('balance') ? null : (
                        <UserIcon className="header__user-icon"/>
                    )}
                    {currentClass.includes('balance') ? title : ((screenSize === 'md' || screenSize === 'sm')  ? ('') : (login))}
                </AccordionSummary>

                <AccordionDetails className={'header__accordion-details'}>
                    <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical outlined button group"
                        sx={{...buttonGroupStyles}}
                    >
                        {buttonList}
                    </ButtonGroup>
                </AccordionDetails>
            </Accordion>
        </ClickAwayListener>
    );
};

export default HeaderAccordion;
