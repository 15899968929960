import { useContext, useLayoutEffect, useEffect } from 'react';
import {
    useGetAppealsQuery,
    useGetSelectedAppealQuery,
    useChangeAppealStatusMutation,
} from '../../../api/apiAppealsSlice';

import Section from '../../../layouts/Section/Section';
import ViewAppeal from './ViewAppeal';
import AppealsList from './AppealsList';
import CreateAppealForm from './CreateAppealForm';

import { socket } from '../../Page';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

import {useSocketListener} from "../../../hooks/useSocketListener";
import {MainLayoutInnerContext} from "../../../layouts/MainLayout/MainLayoutInner";

const Appeals = () => {
    const { clientCheck, dictionary } = useContext(PageContext);
    const { createSlug, content, changeContentHandler, skip, selectedElementId, hash } =
        useContext(PageContainerContext);


    const {
        newAppealsNot,
    } = useContext(MainLayoutInnerContext);

    useLayoutEffect(() => {
        if (hash === '#create' && content !== 'createAppeal') {
            changeContentHandler('createAppeal', '', '', clientCheck ? '' : 'support');
        } else if (hash.includes('#appeal') && content !== 'viewAppeal') {
            const id = hash.slice(hash.indexOf('_') + 1);
            changeContentHandler('viewAppeal', id, true, clientCheck ? '' : 'support');
        } else if (!hash && content !== 'appealsList') {
            changeContentHandler('appealsList', '', '', clientCheck ? '' : 'support');
        }
    }, [hash, content]);

    const {
        data: appeals,
        isLoading: appealsLoading,
        isFetching: appealsFetching,
        isSuccess: appealsSuccess,
        isError: appealsFetchingError,
        error: appealsError,
        refetch: fetchAppeals,
    } = useGetAppealsQuery(
        {
            part: clientCheck ? 'client' : 'admin',
            limit: createSlug('limit'),
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
            search: createSlug('q') || '',
            category_id: createSlug('category_id'),
            status: createSlug('status'),
            role: createSlug('role') || '',
            client_id: createSlug('client_id') || '',
            range_start: createSlug('range_start') || '',
            range_end: createSlug('range_end') || '',
        },
        {skip}
    );

    const {
        data: selectedAppeal,
        isLoading: selectedAppealLoading,
        isFetching: selectedAppealFetching,
        isSuccess: selectedAppealSuccess,
        isError: selectedAppealFetchingError,
    } = useGetSelectedAppealQuery(
        {
            id: content === 'viewAppeal' ? selectedElementId : '',
            part: clientCheck ? 'client' : 'admin',
        },
        { skip: content !== 'viewAppeal' }
    );

    const [
        changeAppealStatus,
        {
            isLoading: chengeddAppeaStatusLoading,
            isFetching: chengeddAppeaStatusFetching,
            isError: chengeddAppeaStatusFetchingError,
        },
    ] = useChangeAppealStatusMutation();

    useEffect(() => {
        const updateAppeals = (data) => {
            if (content === 'appealsList') {
                fetchAppeals();
            }
        };

        socket.on('support.status', updateAppeals);

        return () => {
            socket.off('support.status', updateAppeals);
        };
    }, [content]);

    useEffect(() => {
            if (content === 'appealsList') {
                fetchAppeals();
            }
    }, [content]);

    useEffect(() => {
        const updateAppeals = (data) => {
            if (content === 'appealsList' && clientCheck) {
                fetchAppeals();
            }
        };

        socket.on('support.message.send', updateAppeals);

        return () => {
            socket.off('support.message.send', updateAppeals);
        };
    }, [content]);



    useSocketListener('support.message.send', (data) => {
        if (!clientCheck) {
            if (data.can_update) {
                if (content === 'appealsList') {
                    fetchAppeals();
                }
            }
        }
    });

    const setAppeal = () => {
        if (selectedAppealSuccess) {
            if (clientCheck) {
                return selectedAppeal?.data;
            } else {
                return selectedAppeal?.data?.client;
            }
        } else {
            return [];
        }
    };

    const appealsList = appeals?.data?.supports?.data || [];
    const appeal = setAppeal();
    const appealCategory = selectedAppeal?.data?.category || '';
    const appealId = selectedAppeal?.data?.id || '';
    const appealListPaginationData = appeals?.data?.supports || {};

    const changeStatusHandler = (action) => {
        changeAppealStatus({ id: appealId, action }).unwrap();
    };

    return (
        <Section
            isLoading={appealsLoading || selectedAppealLoading || chengeddAppeaStatusLoading}
            isFetching={appealsFetching || selectedAppealFetching || chengeddAppeaStatusFetching}
            isError={
                appealsFetchingError ||
                selectedAppealFetchingError ||
                chengeddAppeaStatusFetchingError
            }
            error={appealsError}
            title={
                clientCheck && content === 'appealsList'
                    ? dictionary?.my_appeals
                    : clientCheck && content === 'createAppeal'
                    ? dictionary?.create_appeal
                    : !clientCheck && content === 'appealsList'
                    ? dictionary?.appeals_list
                    : dictionary?.view_appeal
            }
            sx={{ maxWidth: content === 'appealsList' ? '100%' : 540 }}
            content={content}
        >
            {(!content || content === 'appealsList') && (
                <AppealsList
                    appealsList={appealsList}
                    appealsSuccess={appealsSuccess}
                    changeContentHandler={changeContentHandler}
                    paginationData={appealListPaginationData}
                />
            )}
            {content === 'viewAppeal' && appeal && (
                <ViewAppeal
                    id={appealId}
                    appeal={appeal}
                    appealCategory={appealCategory}
                    changeStatusHandler={changeStatusHandler}
                />
            )}
            {content === 'createAppeal' && <CreateAppealForm />}
        </Section>
    );
};

export default Appeals;
