import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';

import {
  setContent,
  setSkip,
  setSelectedElementId,
} from '../../reducers/sectionSlice';

import { ReactComponent as VisitorsIcon } from '../../assets/icons/visitor-icon.svg';
import { ReactComponent as DialoguesIcon } from '../../assets/icons/dialogues-icon.svg';
import { ReactComponent as ChannelsIcon } from '../../assets/icons/channels-icon.svg';
import { ReactComponent as EmployeesIcon } from '../../assets/icons/employees-icon.svg';
import { ReactComponent as TemplatesIcon } from '../../assets/icons/templates-icon.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/support-icon.svg';

import NavAccordion from './NavAccordion';
import NavSubItem from './NavSubItem';
import Counter from '../../components/ui/Counter/Counter';

import { PageContext } from '../../pages/Page';
import { MainLayoutInnerContext } from '../MainLayout/MainLayoutInner';

import { setNavLinkName } from '../../data/navLinkData';

import './nav.scss';

const NavItem = ({ route, activeItem, setActiveItem }) => {
  const {
    expandedAccordionOfNav,
    setExpandedAccordionOfNav,
    userOnlineSection,
    clientCheck,
    setAlertMessage,
    dictionary,
    screenSize,
    active,
    setActive,
  } = useContext(PageContext);
  const {
    newAppeals,
    newAppealsNot,
    activeDialogues,
    waitingDialogues,
    appealsCounterSuccess,
    activeDialoguesSuccess,
    waitingDialoguesSuccess,
    newMessagesInActiveDialogues,
  } = useContext(MainLayoutInnerContext);

  const dispatch = useDispatch();

  const {
    path,
    hiddenRoutes,
    counter,
    visitorsOnline,
    initialContent,
    accordion,
  } = route;

  const linkName = setNavLinkName(dictionary).filter(({ id }) => path === id)[0]
    ?.linkName;

  const setInitialProps = (initialContent) => {
    dispatch(setContent(initialContent));
    dispatch(setSkip(false));
    dispatch(setSelectedElementId(''));
  };

  const icon = () => {
    if (screenSize === 'xs' || (screenSize === 'md' && !active)) {
      switch (path) {
        case "channels":
          return <ChannelsIcon className='nav__icon'  />
        case "employees":
          return <EmployeesIcon className='nav__icon'  />
        case "visitors/online":
          return <VisitorsIcon className='nav__icon'  />
        case "dialogues/active":
          return <DialoguesIcon className='nav__icon' />
        case "templates/commands":
          return <TemplatesIcon className='nav__icon'  />
        case "support":
          return <SupportIcon className='nav__icon'  />
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  const singleRoute = () => {
    return (
      <NavLink
        onClick={() => {
          setExpandedAccordionOfNav(false);
          setAlertMessage({});
          setActiveItem(path);
          if (initialContent) {
            setInitialProps(initialContent);
          }
          if (screenSize === 'xs' && active) {
            setActive(!active);
          }
        }}
        className={({ isActive }) =>
          isActive
            ? 'nav__link nav__link--main _active'
            : 'nav__link nav__link--main'
        }
        to={path}
      >
        {icon()}
        {(screenSize !== 'md' || active) && linkName}
      </NavLink>
    );
  };

  const multipleRoutes = () => {
    return (
      <NavAccordion
        path={path}
        icon={icon}
        linkName={(screenSize !== 'md' || active) && linkName}
        expandedAccordionOfNav={expandedAccordionOfNav}
        setExpandedAccordionOfNav={setExpandedAccordionOfNav}
        setInitialProps={setInitialProps}
        accordion={accordion}
      >
        <ul className="nav__sub-list">
          {hiddenRoutes.map((route) => {
            return (
              <NavSubItem
                key={route.path}
                route={route}
                isSuccess={
                  appealsCounterSuccess ||
                  activeDialoguesSuccess ||
                  waitingDialoguesSuccess
                }
                value={
                  !clientCheck
                    ? newAppeals
                    : {
                        counterFirst: activeDialogues,
                        counterSecond: waitingDialogues,
                      }
                }
                setInitialProps={setInitialProps}
              />
            );
          })}
        </ul>
      </NavAccordion>
    );
  };

  const navItemContent = hiddenRoutes ? multipleRoutes(path) : singleRoute();

  return (
    <>
      {(visitorsOnline === userOnlineSection ||
        visitorsOnline === undefined) && (
        <li className="nav__item">
          {navItemContent}
          {counter &&
            ((appealsCounterSuccess &&
              newAppeals &&
              expandedAccordionOfNav !== 'support') ||
              (activeDialoguesSuccess &&
                activeDialogues &&
                expandedAccordionOfNav !== 'dialogues')) && (
              <Counter
                sx={{
                  position: 'absolute',
                  right: screenSize === 'xs' ? '17px' : (screenSize === 'md' && !active ? '-8px' : 14),
                  top: screenSize === 'xs' ? '24px' : (screenSize === 'md' && !active ? '-10px' : 14),
                  pointerEvents: 'none',
                  backgroundColor:
                    !hiddenRoutes && activeItem === 'admin/support'
                      ? 'basicColor.colorWhite'
                      : 'secondaryColor.main',
                  color:
                    !hiddenRoutes && activeItem === 'admin/support'
                      ? 'secondaryColor.main'
                      : 'basicColor.colorWhite',

                  '&::before': {
                    display: (screenSize === 'md' && !active ? 'none' : undefined),
                  },
                }}
                indicator={waitingDialogues || newMessagesInActiveDialogues}
              >
                {appealsCounterSuccess ? newAppeals : activeDialogues}
              </Counter>
            )}
        </li>
      )}
    </>
  );
};

export default NavItem;
