import {useState, createContext, useLayoutEffect, useContext, useEffect} from 'react';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box, Typography,
} from '@mui/material';

import MyButton from '../ui/Button/MyButton';
import ClientsFilter from '../../pages/adminPartPages/Clients/ClientsFilter';
import NotificationsFilter from '../../pages/adminPartPages/Notifications/NotificationsFilter';
import AppealFilter from '../../pages/adminPartPages/Support/AppealFilter';
import PaymentFilter from '../../pages/clientPartPages/Payment/PaymentFilter';
import EmployeesFilter from '../../pages/clientPartPages/Employees/EmployeesFilter';
import VisitorsFilter from '../../pages/clientPartPages/Visitors/VisitorsFilter';
import CommandsFilter from '../../pages/clientPartPages/Templates/CommandsFilter';
import DialoguesFilter from '../../pages/clientPartPages/Dialogues/DialoguesFilter';

import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {ReactComponent as CloseMobileMenuIcon} from '../../assets/icons/close-mobile.svg';

import {PageContext} from '../../pages/Page';
import {PageContainerContext} from '../../pages/PageContainer';
import {theme} from "../../mui/theme";

export const FilterContext = createContext('');

const allFilterParams = [
    'sites',
    'user',
    'visitor',
    'tag',
    'status',
    'range_start',
    'range_end',
    'functional_id',
    'functional_value',
    'category_id',
    'client_id',
    'role',
    'role_id',
    'categories',
    'email',
    'site',
    'count',
];

const FilterAccordion = ({moveToLeft, disabled}) => {
    const {dictionary, content, screenSize} = useContext(PageContext);
    const {queryParams} = useContext(PageContainerContext);

    const [expanded, setExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);
    const [selectQuerySkip, setSelectQuerySkip] = useState(true);

    const cleareFilterFields = () => {
        setClearFilter(true);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        if (isExpanded) {
            setSelectQuerySkip(false);
        }
    };

    // when we click on back/forward browser button or upload the page and there are queryParams
    useLayoutEffect(() => {
        if (queryParams.size && !showButton) {
            if (allFilterParams.some((param) => queryParams.has(param))) {
                setShowButton(true);
                setExpanded('filter');
                setSelectQuerySkip(false);
            }
        }
    }, [queryParams]);

    const [isScrollLocked, setIsScrollLocked] = useState(false);


    useEffect(() => {
        if (expanded === 'filter' && screenSize === 'xs') {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        return () => {
            document.body.style.overflow = "";
        };
    }, [expanded]);

    return (
        <Box sx={{position: 'relative'}}>
            <Accordion
                expanded={expanded === 'filter'}
                className={'toolbar__accordion'}
                onChange={handleChange('filter')}
            >
                <AccordionSummary
                    className={'toolbar__accordion-button'}
                    aria-controls="panel1a-content"
                    sx={{
                        div: {
                            flexDirection: screenSize === 'xs' ? 'row-reverse' : undefined,
                            flexGrow: screenSize === 'xs' ? 'none' : undefined,
                        }
                    }}
                >
                    <MyButton
                        title={dictionary?.filters}
                        buttonClasses={'btn--small'}
                        sx={{
                            left: screenSize !== 'xs' && moveToLeft ? 190 : undefined,
                            width: screenSize === 'xs' && (content === 'dialoguesList' || content === 'payment') ? '100% !important' : undefined,
                            maxWidth: screenSize === 'xs' && (content === 'dialoguesList' || content === 'payment') ? '100% !important' : undefined
                        }}
                        disabled={disabled}
                    />
                </AccordionSummary>
                <FilterContext.Provider
                    value={{
                        showButton,
                        setShowButton,
                        clearFilter,
                        setClearFilter,
                        allFilterParams,
                    }}
                >
                    {screenSize === 'xs' &&
                        <Box
                            sx={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                zIndex: 9,
                            }}
                            onClick={() => setExpanded(false)}
                        />
                    }
                    <AccordionDetails
                        sx={{
                            position: screenSize === 'xs' ? 'fixed' : undefined,
                            width: screenSize === 'xs' ? '-webkit-fill-available' : undefined,
                            zIndex: screenSize === 'xs' ? 999 : undefined,
                            backgroundColor: screenSize === 'xs' ? 'white' : undefined,
                            padding: screenSize === 'xs' ? '20px !important' : undefined,
                            left: screenSize === 'xs' ? '0px' : undefined,
                            top: screenSize === 'xs' ? '80px' : undefined,
                            marginX: screenSize === 'xs' ? '20px' : undefined,
                            borderRadius: screenSize === 'xs' ? '10px' : undefined,
                            '#steps-submit-button': {
                                // width: screenSize === 'xs' && '100% !important',
                                // maxWidth: screenSize === 'xs' && '100% !important',
                            },
                        }}
                        className={'toolbar__accordion-details'}>
                        {screenSize === 'xs' &&
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography sx={{
                                    margin: 0,
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    lineHeight: 1.458,
                                    textAlign: 'left',
                                    color: '#196ca7',
                                    fontFamily: 'Inter',
                                    marginTop: '0px',
                                }}>
                                    {dictionary.filters}</Typography>
                                {showButton && screenSize === 'xs' && (
                                    <MyButton
                                        sx={{
                                            backgroundColor: 'transparent !important',
                                            color: 'var(--secondary-color)',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        title={dictionary?.clear_filters}
                                        buttonClasses={'btn--light btn--small'}
                                        buttonHandler={() => cleareFilterFields()}
                                    />
                                )}
                                <CloseMobileMenuIcon
                                    onClick={() => setExpanded(false)}
                                />
                            </Box>
                        }
                        {content === 'clientsList' && (
                            <ClientsFilter selectQuerySkip={selectQuerySkip}/>
                        )}
                        {content === 'notificationsList' && <NotificationsFilter/>}
                        {content === 'appealsList' && (
                            <AppealFilter
                                selectQuerySkip={selectQuerySkip}
                                clearFilter={clearFilter}
                            />
                        )}
                        {content === 'payment' && (
                            <PaymentFilter
                                selectQuerySkip={selectQuerySkip}
                                clearFilter={clearFilter}
                            />
                        )}
                        {(content === 'employeesList' || content === 'clientInfo') && (
                            <EmployeesFilter selectQuerySkip={selectQuerySkip}/>
                        )}
                        {(content === 'visitorsOnline' || content === 'visitorsList') && (
                            <VisitorsFilter selectQuerySkip={selectQuerySkip}/>
                        )}
                        {content === 'dialoguesList' && (
                            <DialoguesFilter
                                selectQuerySkip={selectQuerySkip}
                                clearFilter={clearFilter}
                            />
                        )}
                        {content === 'commandsList' && (
                            <CommandsFilter selectQuerySkip={selectQuerySkip}/>
                        )}
                    </AccordionDetails>
                </FilterContext.Provider>
            </Accordion>
            {showButton && screenSize !== 'xs' && (
                <MyButton
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: moveToLeft ? 380 : 190,
                    }}
                    title={dictionary?.clear_filters}
                    buttonClasses={'btn--light btn--small'}
                    buttonHandler={() => cleareFilterFields()}
                />
            )}
        </Box>
    );
};

export default FilterAccordion;
