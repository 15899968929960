import React, {useContext} from 'react';

import {
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails, Slider
} from '@mui/material';


import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';
import MyLink from '../../../components/ui/Link/MyLink';

import {ReactComponent as CopyIcon} from '../../../assets/icons/copy.svg';
import {ReactComponent as ViewingIcon} from '../../../assets/icons/hide.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';

import {dateConverter, setStatusLabel} from '../../../helpers/functions';

import {setVisitorsTableHeadings as setHeadCells} from '../../../data/tableHeadingsData';

import {PageContext} from '../../Page';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const boxStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'column',
}));


const boxIconStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '24px',
}));


const typographyStyled = (({}) => ({
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    maxWidth: '280px',
}));

const VisitorsList = ({
                          copy,
                          copiedText,
                          visitorsList,
                          visitorsSuccess,
                          paginationData,
                          changeContentHandler,
                      }) => {
    const {alertMessageContent, navigate, screenSize, dictionary, active} = useContext(PageContext);

    const copyIconStyles = {
        position: 'relative',
        top: 5,
        marginLeft: 1.25,
        cursor: 'pointer',
    };
    document.querySelectorAll('.test .accordion__header:nth-child(even)').forEach(el => {
        el.style.backgroundColor = 'red !important';
    });
    const copyTextHandler = (text) => {
        if (copiedText === undefined) {
            copy(text);
            alertMessageContent(dictionary?.data_copied, 'success');
        }
    };

    return (
        <>
            <MyToolbar
                filter={true}
                search={dictionary?.visitors_list_search_placeholder}
                paginationData={paginationData}
                changeContentButton={false}
            />
            {screenSize === 'xs' && <Box>
                {visitorsList.length === 0 && visitorsSuccess && <EmptyListAlertText/>}
                {visitorsList.length > 0 &&
                    visitorsList.map(
                        ({id, fullname, email, phone, status, blocking = ''}) => {
                            return (
                                <Accordion
                                    sx={{
                                        position: 'inherit',
                                    }}
                                    className='accordion__item'
                                >
                                    <AccordionSummary
                                        sx={{
                                            minHeight: '50px',
                                            fontSize: 'var(--fz-400)',
                                            padding: '13px 16px',
                                            lineHeight: 1.5,
                                            borderRadius: 0,
                                            display: 'flex',
                                            width: '100%',
                                            fontWeight: '600 !important',
                                            textAlign: 'left',
                                            border: 'none',
                                            color: 'var(--basic-color)',
                                            transition: 'background-color 50ms',
                                        }}
                                        className='accordion__header'
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            sx={{
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                maxWidth: '50vw',
                                            }}>
                                            {fullname}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingX: '16px !important',
                                            gap: '12px'
                                        }}
                                    >
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption"
                                                        color="text.secondary">{dictionary.full_name}</Typography>
                                            <Typography
                                                sx={typographyStyled}>
                                                {fullname}
                                            </Typography>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">E-mail</Typography>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                color: 'var(--secondary-color)'
                                            }}>
                                                {!email && '-'}
                                                {email}
                                                {email && (
                                                    <MyTooltip
                                                        position={'top'}
                                                        title={dictionary?.copy}
                                                    >
                                                        <CopyIcon
                                                            className="copy-icon"
                                                            onClick={() => copyTextHandler(email)}
                                                        />
                                                    </MyTooltip>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption" color="text.secondary">
                                                {dictionary.phone}
                                            </Typography>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                color: 'var(--secondary-color)'
                                            }}>
                                                {!phone && '-'}
                                                {phone
                                                    ? phone.startsWith('+')
                                                        ? phone
                                                        : '+' + phone
                                                    : ''}
                                                {phone && (
                                                    <MyTooltip
                                                        position={'top'}
                                                        title={dictionary?.copy}
                                                    >
                                                        <CopyIcon
                                                            className="copy-icon"
                                                            onClick={() => copyTextHandler(phone)}
                                                        />
                                                    </MyTooltip>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption"
                                                        color="text.secondary">{dictionary.status}</Typography>
                                            <Typography className={status}>
                                                <Typography className={status}>
                                                    {setStatusLabel(status, dictionary)}
                                                </Typography>
                                            </Typography>
                                            {blocking &&
                                                blocking.map(({site, expiration}, index) => {
                                                    return (
                                                        <Box key={index}>
                                                            <MyLink
                                                                link={`${site?.protocol}://${site?.domain}`}
                                                                text={site?.domain}
                                                            />
                                                            <Typography>
                                                                до {dateConverter(expiration)}
                                                            </Typography>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                        <Box sx={boxIconStyled}>
                                            <MyTooltip position={'top'} title={dictionary?.viewing}>
                                                <ViewingIcon
                                                    onClick={() => {
                                                        changeContentHandler(
                                                            'viewVisitor',
                                                            id,
                                                            true,
                                                            'visitors'
                                                        );
                                                        navigate(`/visitors/all#view_${id}`);
                                                    }}
                                                />
                                            </MyTooltip>
                                            <MyTooltip position={'top'} title={dictionary?.edit}>
                                                <EditIcon
                                                    onClick={() => {
                                                        changeContentHandler(
                                                            'editVisitor',
                                                            id,
                                                            true,
                                                            'visitors'
                                                        );
                                                        navigate(`/visitors/all#visitor_${id}`);
                                                    }}
                                                />
                                            </MyTooltip>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }
                    )}
            </Box>}
            {screenSize !== 'xs' && <TblContainer tableSx={{
                overflowX: screenSize === 'sm' || screenSize === 'md' ? 'overlay' : undefined,
                overflowY: 'hidden',
            }} tableClass={'table-main--big'}>
                <TblHead setHeadCells={setHeadCells}/>
                <TableBody>
                    {visitorsList.length === 0 && visitorsSuccess && <EmptyListAlertText/>}
                    {visitorsList.length > 0 &&
                        visitorsList.map(
                            ({id, fullname, email, phone, status, blocking = ''}) => {
                                return (
                                    <TableRow className="table-main__row" key={id}>

                                        <TableCell>{fullname}</TableCell>
                                        <TableCell
                                            sx={{
                                                color: 'secondaryColor.main',
                                                '& svg': {
                                                    ...copyIconStyles,
                                                },
                                            }}
                                        >
                                            {email}
                                            {email && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={dictionary?.copy}
                                                >
                                                    <CopyIcon
                                                        className="copy-icon"
                                                        onClick={() => copyTextHandler(email)}
                                                    />
                                                </MyTooltip>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: 'secondaryColor.main',
                                                '& svg': {
                                                    ...copyIconStyles,
                                                },
                                            }}
                                        >
                                            {phone
                                                ? phone.startsWith('+')
                                                    ? phone
                                                    : '+' + phone
                                                : ''}
                                            {phone && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={dictionary?.copy}
                                                >
                                                    <CopyIcon
                                                        className="copy-icon"
                                                        onClick={() => copyTextHandler(phone)}
                                                    />
                                                </MyTooltip>
                                            )}
                                        </TableCell>
                                        <TableCell className="status">
                                            <Typography className={status}>
                                                {setStatusLabel(status, dictionary)}
                                            </Typography>
                                            {blocking &&
                                                blocking.map(({site, expiration}, index) => {
                                                    return (
                                                        <Box key={index}>
                                                            <MyLink
                                                                link={`${site?.protocol}://${site?.domain}`}
                                                                text={site?.domain}
                                                            />
                                                            <Typography>
                                                                до {dateConverter(expiration)}
                                                            </Typography>
                                                        </Box>
                                                    );
                                                })}
                                        </TableCell>
                                        <TableCell className="action">
                                            <MyTooltip position={'top'} title={dictionary?.viewing}>
                                                <ViewingIcon
                                                    onClick={() => {
                                                        changeContentHandler(
                                                            'viewVisitor',
                                                            id,
                                                            true,
                                                            'visitors'
                                                        );
                                                        navigate(`/visitors/all#view_${id}`);
                                                    }}
                                                />
                                            </MyTooltip>
                                            <MyTooltip position={'top'} title={dictionary?.edit}>
                                                <EditIcon
                                                    onClick={() => {
                                                        changeContentHandler(
                                                            'editVisitor',
                                                            id,
                                                            true,
                                                            'visitors'
                                                        );
                                                        navigate(`/visitors/all#visitor_${id}`);
                                                    }}
                                                />
                                            </MyTooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                </TableBody>
            </TblContainer>}
            <TblPagination data={paginationData} invalidateTag={'Visitors'}/>
        </>
    );
};

export default VisitorsList;
