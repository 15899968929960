import React, {useState, useContext, useLayoutEffect} from 'react';
import {useGetPaymentHistoryQuery} from '../../../api/clientPartApi/apiPaymentHistorySlice';

import {v4 as uuidv4} from 'uuid';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import {FlexBox} from '../../../mui/theme';

import Section from '../../../layouts/Section/Section';
import PaymentAccordion from './PaymentAccordion';
import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import {setPaymendHistoryTableHeadings as setHeadCells} from '../../../data/tableHeadingsData';

import {PageContext} from '../../Page';
import {PageContainerContext} from '../../PageContainer';

import {dateConverter, setFunctionalItemValue, setStatusLabel} from '../../../helpers/functions';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MyTooltip from "../../../components/ui/Tooltip/MyTooltip";
import {secondary} from "../../../helpers/variables";


const boxStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'column',
}));


const boxIconStyled = (({}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '24px',
}));


const typographyStyled = (({}) => ({
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    maxWidth: '280px',
}));


const PaymentHistory = () => {
    const {dictionary, language, screenSize} = useContext(PageContext);
    const {createSlug, queryParams, content, changeContentHandler, pathname} =
        useContext(PageContainerContext);

    const [expanded, setExpanded] = useState(false);

    useLayoutEffect(() => {
        if (pathname === '/payment' && content !== 'payment') {
            changeContentHandler('payment');
        }
    }, [pathname, content]);

    const {
        data: paymentHistory,
        isLoading: paymentHistoryLoading,
        isFetching: paymentHistoryFetching,
        isSuccess: paymentHistorySuccess,
        isError: paymentHistoryFetchingError,
        error: paymentHistoryError,
    } = useGetPaymentHistoryQuery({
        page: createSlug('page'),
        sortBy: createSlug('sort_by'),
        sortOrder: createSlug('sort_order'),
        rangeStart: createSlug('range_start'),
        rangeEnd: createSlug('range_end'),
    });

    const paymentHistoryList = paymentHistory?.data?.payments?.data || [];
    const paymentHistoryListPagination = paymentHistory?.data?.payments || [];
    const paymentsTotal = paymentHistory?.data?.payments?.payments_total || '';

    const tableBodyStyles = {
        '& .MuiTableRow-root:not(:last-of-type)': {
            marginBottom: 1.25,
        },
        '& .MuiTableCell-root:nth-of-type(1)': {
            flex: '1 1 auto',
        },
        '& .MuiTableCell-root:nth-of-type(2), & .MuiTableCell-root:nth-of-type(3)': {
            maxWidth: 80,
            width: '100%',
        },
    };

    function calculateTotalPrice(items) {
        return items.reduce((total, item) => {
            return total + item.price;
        }, 0);
    }

    return (
        <Section
            isLoading={paymentHistoryLoading}
            isFetching={paymentHistoryFetching}
            isError={paymentHistoryFetchingError}
            error={paymentHistoryError}
            title={dictionary?.payment_history}
            sx={{maxWidth: 920}}
        >
            <MyToolbar filter={true} changeContentButton={false}/>
            {queryParams.has('range_start') && paymentHistoryList.length > 0 && (
                <FlexBox className="row-10" sx={{marginBottom: 3.75}}>
                    <Typography>{dictionary?.total_for_period}</Typography>
                    <Typography fontWeight={500} color="secondaryColor.main">
                        {paymentsTotal} UAH
                    </Typography>
                </FlexBox>
            )}
            {screenSize === 'xs' && <Box>
                {paymentHistoryList.length === 0 && paymentHistorySuccess && (
                    <EmptyListAlertText/>
                )}
                {paymentHistoryList.length > 0 &&
                    paymentHistoryList.map(({id, date, functional}) => {
                            return (
                                <Accordion
                                    sx={{
                                        position: 'inherit',
                                    }}
                                    className='accordion__item'
                                >
                                    <AccordionSummary
                                        sx={{
                                            minHeight: '50px',
                                            fontSize: 'var(--fz-400)',
                                            padding: '13px 16px',
                                            lineHeight: 1.5,
                                            borderRadius: 0,
                                            display: 'flex',
                                            width: '100%',
                                            fontWeight: '600 !important',
                                            textAlign: 'left',
                                            border: 'none',
                                            color: 'var(--basic-color)',
                                            transition: 'background-color 50ms',
                                        }}
                                        className='accordion__header'
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            sx={{
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                maxWidth: '230px',
                                            }}>
                                            {dateConverter(date, 'date')}
                                            <Typography></Typography>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingX: '16px !important',
                                            gap: '12px'
                                        }}
                                    >
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption"
                                                        color="text.secondary">{dictionary.functional}</Typography>
                                            <Typography sx={{fontWeight: 600}}  variant="caption"
                                                        color="text.primiry">{dictionary.total_price}</Typography>
                                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                <Typography
                                                    sx={typographyStyled}
                                                >
                                                    {calculateTotalPrice(functional)} UAH
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption"> {functional.find((el) => el.name.ru === 'Количество сайтов')?.name[language]}</Typography>
                                            <Box sx={{display: 'flex', justifyContent: 'space-between', color: secondary.main}}>
                                                <Typography
                                                    sx={typographyStyled}
                                                >
                                                    {functional.find((el) => el.name.ru === 'Количество сайтов')?.value} .
                                                </Typography>
                                                <Typography
                                                    sx={typographyStyled}
                                                >
                                                    {functional.find((el) => el.name.ru === 'Количество сайтов')?.price} UAH
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={boxStyled}>
                                            <Typography variant="caption">{functional.find((el)=> el.name.ru === 'Максимальный объем хранилища')?.name[language]}</Typography>
                                            <Box sx={{display: 'flex', justifyContent: 'space-between',  color: secondary.main}}>
                                                <Typography
                                                    sx={typographyStyled}
                                                >
                                                    {functional.find((el)=> el.name.ru === 'Максимальный объем хранилища')?.value} {dictionary.mb}
                                                </Typography>
                                                <Typography
                                                    sx={typographyStyled}
                                                >
                                                    {functional.find((el)=> el.name.ru === 'Максимальный объем хранилища')?.price} UAH
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={boxIconStyled}>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }
                    )}
            </Box>}
            {screenSize !== 'xs' && <TblContainer>
                <TblHead setHeadCells={setHeadCells}/>
                <TableBody>
                    {paymentHistoryList.length === 0 && paymentHistorySuccess && (
                        <EmptyListAlertText/>
                    )}
                    {paymentHistoryList.length > 0 &&
                        paymentHistoryList.map(({id, date, functional}) => {
                            return (
                                <TableRow
                                    className="table-main__row"
                                    sx={{verticalAlign: 'top'}}
                                    key={id}
                                >
                                    <TableCell sx={{width: '16.31%'}}>
                                        {dateConverter(date, 'date')}
                                    </TableCell>
                                    <TableCell sx={{width: '83.69%'}}>
                                        <PaymentAccordion
                                            id={id}
                                            functional={functional}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                        >
                                            <Table>
                                                <TableBody sx={{...tableBodyStyles}}>
                                                    {functional.length > 0 &&
                                                        functional.map(
                                                            ({name, price, type, value}) => {
                                                                return (
                                                                    <TableRow
                                                                        className="table-main__row--flex"
                                                                        key={uuidv4()}
                                                                        sx={{
                                                                            '& .MuiTableCell-root:not(:first-of-type)':
                                                                                {
                                                                                    color: 'secondaryColor.main',
                                                                                },
                                                                        }}
                                                                    >
                                                                        <TableCell>
                                                                            {name?.[language]}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                justifyContent:
                                                                                    'flex-start',
                                                                            }}
                                                                        >
                                                                            {setFunctionalItemValue(
                                                                                value,
                                                                                type,
                                                                                dictionary
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                fontWeight: 500,
                                                                                justifyContent:
                                                                                    'flex-end',
                                                                            }}
                                                                        >
                                                                            {price} UAH
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            }
                                                        )}
                                                </TableBody>
                                            </Table>
                                        </PaymentAccordion>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>}
            <TblPagination data={paymentHistoryListPagination} invalidateTag={'Payment History'}/>
        </Section>
    );
};

export default PaymentHistory;
