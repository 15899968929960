import {useState, useEffect, useContext} from 'react';

import classNames from 'classnames';

import {setContent} from '../../reducers/sectionSlice';

import {Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem, Typography} from '@mui/material';
import {ClickAwayListener} from '@mui/base/ClickAwayListener';
import MenuIcon from '@mui/icons-material/Menu';

import Navbar from '../Navbar/Navbar';
import LanguageTabs from '../../components/Tabs/LanguageTabs';
import {FlexBox} from '../../mui/theme';

import {ReactComponent as Logo} from '../../assets/logo-en.svg';
import {ReactComponent as LogoMobile} from '../../assets/logo-small.svg';
import {ReactComponent as CloseMenuIcon} from '../../assets/icons/close.svg';
import {ReactComponent as CloseMobileMenuIcon} from '../../assets/icons/close-mobile.svg';
import {ReactComponent as NotificationIcon} from '../../assets/icons/notification-icon.svg';
import {ReactComponent as IconLeft} from '../../assets/icons/arrows-left.svg';
import {ReactComponent as IconRight} from '../../assets/icons/arrows-right.svg';
import {createMuiComponent} from '../../mui/functions';

import {PageContext} from '../../pages/Page';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HeaderSwitchers from "../Header/HeaderSwitchers";
import MobileSettings from "./MobileSettings";
import {setUserButtons} from "../../data/headerData";
import HeaderAccordion from "../Header/HeaderAccordion";
import MyButton from "../../components/ui/Button/MyButton";
import HeaderNotifications from "../Header/HeaderNotifications";
import {useGetNewNotificationsQuery} from "../../api/clientPartApi/apiClientPartNotificationsSlice";
import {secondary} from "../../helpers/variables";
import zIndex from "@mui/material/styles/zIndex";

const MySidebar = createMuiComponent(Box, 'MuiSidebar');
const MySidebarButton = createMuiComponent(Box, 'MuiSidebarButton');

const Sidebar = () => {
    const {
        clientCheck,
        dictionary,
        screenSize,
        active,
        setActive,
        dispatch,
        navigate,
        indexPath,
        setExpandedAccordionOfNav,
        userStatusCounter,
        setUserStatusCounter,
        skipNotifications,
        setSkipNotifications,
        showNotifications,
        setShowNotifications
    } =
        useContext(PageContext);


    const {
        data: newNotifications,
        error: newNotificationsError,
        refetch: fetchNewNotifications,
    } = useGetNewNotificationsQuery(undefined, {
        skip: !clientCheck,
    });

    const showNotificationsHandler = () => {
        setShowNotifications(true);
        setSkipNotifications(false);
    };

    const newNotificationsCount = newNotifications?.notifications?.count || '';

    const removeAddedClasses = () => {
        if (window.innerWidth >= 1600) {
            document.body.classList.remove('lock');
            setActive(false);
        }
    };

    useEffect(() => {
        if (screenSize === 'xs' && active) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [screenSize, active]);


    useEffect(() => {
        if (active && (screenSize === 'lg' || screenSize === 'sm')) {
            document.body.classList.add('lock');

            window.addEventListener('resize', removeAddedClasses);

            return () => window.removeEventListener('resize', removeAddedClasses);
        } else {
            document.body.classList.remove('lock');
        }
    }, [active]);

    const activeClass = classNames('', {
        active,
    });

    const returnToTheHomePage = () => {
        dispatch(setContent(''));
        setExpandedAccordionOfNav('');
        navigate(`/${indexPath}`);
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (active && screenSize !== 'md') {
                    setActive(!active);
                }
            }}
        >
            <Box sx={{display: 'flex', gap: '50px',}}>
                <MySidebarButton
                    className={activeClass}
                    onClick={() => setActive(!active)}
                >
                    {active ? (
                        screenSize === 'xs' || screenSize === 'sm' ? <CloseMobileMenuIcon/> : <CloseMenuIcon/>
                    ) : (
                        <MenuIcon
                            sx={{color: screenSize === 'xs' || screenSize === 'sm' ? '#196CA7' : 'white'}}/>
                    )}

                </MySidebarButton>
                <MySidebar className={activeClass}><Box sx={{
                    padding: screenSize !== 'xs' ? '24px 24px 0 24px' : '14px 23px 0 23px',
                    marginBottom: 1.25,
                    display: screenSize === 'md' ? 'flex' : undefined,
                    flexDirection: (screenSize === 'md' && !active) ? 'row-reverse' : undefined,

                }}>
                    {screenSize !== 'xs' ? (
                        screenSize === 'md' && !active ? (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '3px solid var(--basic-color-100)',
                                borderRadius: '16px',
                                backgroundColor: !active ? '#F5F6FA' : undefined,
                                width: '58px',
                                height: '58px',
                            }}
                            >
                                <LogoMobile
                                    style={{cursor: 'pointer', width: '42px', height: '42px'}}
                                    onClick={() => {
                                        returnToTheHomePage();
                                        setActive(!active);
                                    }}
                                />
                            </Box>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        svg: {
                                            maxWidth: (screenSize === 'md' && active) ? '170px' : undefined,
                                            maxHeight: (screenSize === 'md' && active) ? '36px' : undefined,
                                            marginRight: (screenSize === 'md' && active) ? '16px' : undefined,
                                        }
                                    }}>
                                    <Logo
                                        style={{cursor: 'pointer'}}
                                        onClick={returnToTheHomePage}
                                    />
                                </Box>
                                <Box sx={{
                                    display: (screenSize === 'md' && active) ? 'flex' : 'none',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '3px solid var(--basic-color-100)',
                                    borderRadius: '10px',
                                    width: '36px',
                                    height: '36px',
                                    cursor: 'pointer',
                                }}
                                     onClick={() => {
                                         setActive(false);
                                     }}>
                                    <IconLeft
                                    />
                                </Box>
                            </>
                        )
                    ) : (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'var(--basic-color-100)',
                            borderRadius: '10px',
                            width: '40px',
                            height: '40px',
                        }}>
                            <LogoMobile
                                style={{cursor: 'pointer', width: '24px', height: '24px',}}
                                onClick={() => {
                                    returnToTheHomePage();
                                    setActive(!active);
                                }}
                            />
                        </Box>
                    )}
                </Box>

                    <FlexBox
                        className="col-40"
                        sx={{
                            height: '100%',
                            justifyContent: (screenSize === 'xs' || screenSize === 'md') ? 'start !important' : 'space-between',
                            padding: screenSize !== 'xs' ? '0 24px 24px 24px' : '0 0 0 0',
                            gap: screenSize === 'xs' && '0 !important' || screenSize === 'md' && '16px !important',
                            overflow: 'auto',
                            marginTop: 1.25,
                        }}
                    >

                        <Box sx={{
                            display: (screenSize === 'md' && !active) ? 'flex' : 'none',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 'auto',
                            border: '3px solid var(--basic-color-100)',
                            borderRadius: '10px',
                            width: '58px',
                            height: '58px',
                            cursor: 'pointer',
                        }}
                             onClick={() => {
                                 setActive(true);
                             }}>
                            <IconRight
                            />
                        </Box>
                        <Navbar
                            userStatusCounter={userStatusCounter}
                            setUserStatusCounter={setUserStatusCounter}
                        />
                        {screenSize === 'xs' && <Box
                            icon={true}
                            buttonClasses={'btn--small btn--notification'}
                            onClick={() => {
                                setActive(false);
                                showNotificationsHandler();
                            }}
                            title={dictionary?.notifications}
                            sx={{
                                width: '100% !important',
                                background: 'transparent',
                                color: 'var(--basic-color)',
                                minHeight: '70px !important',
                                textAlign: 'left',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                padding: '13px 16px !important',
                                fontSize: '16px !important',
                                fontWeight: '600 !important',
                                borderBottom: '2px solid var(--basic-color-100)',
                                alignItems: 'center',
                                gap: '16px',

                                '&::before': {
                                    display: 'none',
                                },
                            }}

                        >
                            <NotificationIcon/>
                            {dictionary.notifications}
                        </Box>
                        }
                        {screenSize === 'xs' && <MobileSettings/>}
                        {screenSize === 'xs' && <HeaderAccordion
                            currentClass={'header__accordion-button header__accordion-button--user'}
                            buttons={setUserButtons}
                            userStatusCounter={userStatusCounter}
                            setUserStatusCounter={setUserStatusCounter}
                            sidebarMenu={true}
                        />}
                        {screenSize !== 'xs' && screenSize !== 'md' && <LanguageTabs
                            onlyTabs={true}
                            tabsClass={'tabs-language--sidebar'}
                        />}

                        <HeaderNotifications
                            showNotifications={showNotifications}
                            setShowNotifications={setShowNotifications}
                            skipNotifications={skipNotifications}
                            setSkipNotifications={setSkipNotifications}
                            fetchNewNotifications={fetchNewNotifications}
                        />
                    </FlexBox>
                </MySidebar>
            </Box>
        </ClickAwayListener>
    );
};

export default Sidebar;
