import {useContext, useLayoutEffect, useEffect, useState} from 'react';
import {useGetSiteQuery} from '../../../../api/clientPartApi/apiSitesSlice';

import Section from '../../../../layouts/Section/Section';
import SitesForm from './SitesForm';

import {
    getSiteFromLocalStorage,
    saveToLocalStorageArray
} from '../../../../helpers/functions';

import {PageContext} from '../../../Page';
import {PageContainerContext} from '../../../PageContainer';

const Site = () => {
    const {dictionary} = useContext(PageContext);
    const {content, changeContentHandler, selectedElementId} = useContext(PageContainerContext);
    const [siteId, setSiteId] = useState('');

    const currentUrl = window.location.href;

    const match = currentUrl.match(/#([^#]*)/);
    const extractedId = match ? match[1] : null;

    useLayoutEffect(() => {
        if (!content && getSiteFromLocalStorage("sites",`${extractedId}`)?.id) {
            changeContentHandler('editSite');
        } else if (!content && !extractedId.length < 8) {
            changeContentHandler('createSite');
        }
    }, [content]);

    function beforeUnloadHandler(e) {
        e.preventDefault();
        e.returnValue = '';
    }

    useEffect(() => {
        if (content === 'editSite') {
            window.addEventListener('beforeunload', beforeUnloadHandler, true);

            return () => window.removeEventListener('beforeunload', beforeUnloadHandler, true);
        }
    }, [content]);

    const lastSelectedSite = JSON.parse(localStorage.getItem(`${extractedId}`))

    const {
        data: selectedSite,
        isLoading: selectedSiteLoading,
        isFetching: selectedSiteFetching,
        isSuccess: selectedSiteSuccess,
        isError: selectedSiteFetchingError,
        error: selectedSiteError,
    } = useGetSiteQuery(
        content === 'editSite' ? extractedId?.length > 8 ? extractedId : (siteId ? siteId : '') : siteId ? siteId : ''
        //  {
        //     skip: !skip,
        // }
    )

    useEffect(() => {
        if (selectedSiteSuccess) {
            if (siteId && extractedId?.length > 8) {
                const siteData = {
                    siteId: siteId,
                    ...selectedSite?.data,
                };
                saveToLocalStorageArray("sites", siteData);
            } else if (extractedId?.length > 8) {
                const siteData = {
                    siteId: extractedId,
                    ...selectedSite?.data, // Данные сайта
                };
                saveToLocalStorageArray("sites", siteData);
            }
        }
    }, [extractedId, siteId, selectedSite, selectedSiteSuccess]);


    const site = selectedSiteSuccess ? selectedSite?.data : '';

    // const site = JSON.parse(localStorage.getItem(`${extractedId}`))



    return (
        <>
            <Section
                key={site.id === selectedElementId}
                isLoading={selectedSiteLoading}
                isFetching={selectedSiteFetching}
                isError={selectedSiteFetchingError}
                error={selectedSiteError}
                title={content === 'createSite' ? dictionary?.create_site : dictionary?.edit_site}
                sx={{maxWidth: 730}}
                content={content}
            >
                <SitesForm
                    content={content}
                    changeContentHandler={changeContentHandler}
                    extractedId={extractedId}
                    site={site}
                    siteId={siteId}
                    setSiteId={setSiteId}
                />
            </Section>
        </>
    );
};

export default Site;
